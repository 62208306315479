import React from 'react'
import ReactDOM from 'react-dom'
import Header from './components/header'
import Home from './pages/home'
import About from './pages/about'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
// import PortfolioCaseStudy1 from './pages/portfolios/portfolio-case-study1'
import PortfolioCaseStudy2 from './pages/portfolios/portfolio-case-study2'
// import PortfolioCaseStudy3 from './pages/portfolios/portfolio-case-study3'
import PortfolioCaseStudy4 from './pages/portfolios/portfolio-case-study4'
// import PortfolioCaseStudy5 from './pages/portfolios/portfolio-case-study5'
// import PortfolioCaseStudy6 from './pages/portfolios/portfolio-case-study6'
import PortfolioCaseStudy7 from './pages/portfolios/portfolio-case-study7'
import PortfolioCaseStudy8 from './pages/portfolios/portfolio-case-study8'
import PortfolioCaseStudy9 from './pages/portfolios/portfolio-case-study9'
import PortfolioCaseStudy10 from './pages/portfolios/portfolio-case-study10'
import PortfolioCaseStudy11 from './pages/portfolios/portfolio-case-study11'
import PortfolioCaseStudy12 from './pages/portfolios/portfolio-case-study12'
import PortfolioCaseStudy13 from './pages/portfolios/portfolio-case-study13'
import PortfolioCaseStudy14 from './pages/portfolios/portfolio-case-study14'
import PortfolioCaseStudy15 from './pages/portfolios/portfolio-case-study15'
import PortfolioCaseStudy16 from './pages/portfolios/portfolio-case-study16'
import logo from './assets/img/svg/logo.svg'
import './assets/sass/style.sass'

class Loading extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      loading: true,
      animateOverlay: false,
			isOpen: false
    }
  }

	menuOpen = e => {
		this.setState({
		  isOpen: this.state.isOpen ? false : true 
		})
	}

	menuClose = e => {
		this.setState({
		  isOpen: false 
		})
	}

  componentDidMount (){
    document.title = "Jonhsson Studio"
    setTimeout(() => this.setState({ animateOverlay: true }), 2005); 
    setTimeout(() => this.setState({ loading: false }), 2000); 
  }
  
  render (){
    const { loading, animateOverlay } = this.state;
    
    if(loading) {
      return <div className="loading"><img alt="" src={logo} /></div>;
    }
    
    return (
      <>
      <div className="loading active"><img alt="" src={logo} /></div>
      <div className={`overlay-site ${animateOverlay ? "active" : ""}`}></div>
        <div className={`site ${window.location.pathname === '/' ? 'home': ''} ${window.location.pathname === '/about' ? 'about': ''}`}>
          <div className="border-top" />
          <div className="border-line" />
          <div className="area-site">
            <BrowserRouter>
              <Header menuOpen={this.menuOpen}
                      menuClose={this.menuClose}
                      menu={this.state.isOpen} />
              <Switch>
                  <Route path="/" exact={true} render={props => <Home {...props} menuClose={this.menuClose} />} />
                  <Route path="/about" exact={true} render={props => <About {...props} menuClose={this.menuClose} />} />
                  <Route path="/portfolio/949-mississauga" render={props => <PortfolioCaseStudy2 {...props} menuClose={this.menuClose} />} />
                  <Route path="/portfolio/7176-puslinch" render={props => <PortfolioCaseStudy9 {...props} menuClose={this.menuClose} />} />
                  <Route path="/portfolio/135-stoneycreek" render={props => <PortfolioCaseStudy10 {...props} menuClose={this.menuClose} />} />
                  <Route path="/portfolio/109-oakville" render={props => <PortfolioCaseStudy4 {...props} menuClose={this.menuClose} />} />
                  <Route path="/portfolio/26-markham" render={props => <PortfolioCaseStudy11 {...props} menuClose={this.menuClose} />} />
                  <Route path="/portfolio/617-mississauga" render={props => <PortfolioCaseStudy8 {...props} menuClose={this.menuClose} />} />
                  <Route path="/portfolio/10193-bolton" render={props => <PortfolioCaseStudy12 {...props} menuClose={this.menuClose} />} />
                  <Route path="/portfolio/2-stoneycreek" render={props => <PortfolioCaseStudy7 {...props} menuClose={this.menuClose} />} />
                  <Route path="/portfolio/233-princeedwardcounty" render={props => <PortfolioCaseStudy13 {...props} menuClose={this.menuClose} />} />
                  <Route path="/portfolio/533-vaughan" render={props => <PortfolioCaseStudy14 {...props} menuClose={this.menuClose} />} />
                  <Route path="/portfolio/interior-design" render={props => <PortfolioCaseStudy15 {...props} menuClose={this.menuClose} />} />
                  <Route path="/portfolio/projects-turkey" render={props => <PortfolioCaseStudy16 {...props} menuClose={this.menuClose} />} />
                  {/* <Route path="/portfolio/505-belwood" render={props => <PortfolioCaseStudy1 {...props} menuClose={this.menuClose} />} />
                  <Route path="/portfolio/97-wasaga-beach" render={props => <PortfolioCaseStudy3 {...props} menuClose={this.menuClose} />} />
                  <Route path="/portfolio/casa-portugal" render={props => <PortfolioCaseStudy5 {...props} menuClose={this.menuClose} />} />
                  <Route path="/portfolio/195-galaxy-blvd" render={props => <PortfolioCaseStudy6 {...props} menuClose={this.menuClose} />} /> */}
                  
                  
                  
              </Switch>
            </BrowserRouter>
          </div>
          <div className="border-bottom" />
        </div>
      </>
    ); 
  }
}

ReactDOM.render(
  <>
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <link rel="stylesheet" href="https://use.typekit.net/ecl5oea.css" />
    <Loading />
  </>,
  document.getElementById('root')
);

// reportWebVitals();
