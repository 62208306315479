import React, { Component } from 'react';
import { Spring } from 'react-spring/renderprops';
import VisibilitySensor from '../../components/visibility-sensor';
import Footer from '../../components/footer';
// import YouTube from 'react-youtube'
import interiorPhoto1 from '../../assets/img/InteriorDesign/13.jpg';
import interiorPhoto2 from '../../assets/img/InteriorDesign/14.jpg';
import interiorPhoto3 from '../../assets/img/InteriorDesign/15.jpg';
import interiorPhoto4 from '../../assets/img/InteriorDesign/20.jpg';
import interiorPhoto5 from '../../assets/img/InteriorDesign/10.jpg';
import interiorPhoto6 from '../../assets/img/InteriorDesign/8.png';
import interiorPhoto7 from '../../assets/img/InteriorDesign/9.jpg';
import interiorPhoto8 from '../../assets/img/InteriorDesign/3.png';
import interiorPhoto9 from '../../assets/img/InteriorDesign/7.png';
import interiorPhoto10 from '../../assets/img/InteriorDesign/6.png';

import interiorPhoto1M from '../../assets/img/mobile/InteriorDesign/13.jpg';
import interiorPhoto2M from '../../assets/img/mobile/InteriorDesign/14.jpg';
import interiorPhoto3M from '../../assets/img/mobile/InteriorDesign/15.jpg';
import interiorPhoto4M from '../../assets/img/mobile/InteriorDesign/20.jpg';
import interiorPhoto5M from '../../assets/img/mobile/InteriorDesign/10.jpg';
import interiorPhoto6M from '../../assets/img/mobile/InteriorDesign/8.png';
import interiorPhoto7M from '../../assets/img/mobile/InteriorDesign/9.jpg';
import interiorPhoto8M from '../../assets/img/mobile/InteriorDesign/3.png';
import interiorPhoto9M from '../../assets/img/mobile/InteriorDesign/7.png';
import interiorPhoto10M from '../../assets/img/mobile/InteriorDesign/6.png';

class PortfolioCaseStudy15 extends Component {
  componentDidMount() {
    document.title = 'Interior Design • Johnsson Studio';
    window.scrollTo(0, 0);
    this.props.menuClose();
  }

  render() {
    // const opts = {
    //     height: '390',
    //     width: '640',
    //     playerVars: {
    //       autoplay: 1,
    //       controls: 1,
    //       mute: 1,
    //       rel: 0
    //     }
    // }
    return (
      <>
        <div id="internal-page">
          <section id="imageFeaturedProfile" className="no-top">
            <div className="container full-image">
              <VisibilitySensor partialVisibility={true} once>
                {({ isVisible }) => (
                  <>
                    <Spring
                      delay={1000}
                      to={{
                        opacity: isVisible ? '1' : '0',
                        transform: isVisible
                          ? 'translateY(0px)'
                          : 'translateY(300px)',
                      }}
                    >
                      {(props) => (
                        <div
                          className={`picture-about image-effect image-delay-1 ${
                            isVisible ? 'active' : ''
                          }`}
                          style={{ ...props }}
                        >
                        <picture>
                              <source
                                  media="(max-width: 1170px)"
                                  srcSet={interiorPhoto1M}
                                  type="image/jpg"
                              />
                              <source
                                  media="(min-width: 1171px)"
                                  srcSet={interiorPhoto1}
                                  type="image/jpg"
                              />
                              <img src={interiorPhoto1} alt="Interior Design" />
                              </picture>
                        </div>
                      )}
                    </Spring>
                  </>
                )}
              </VisibilitySensor>
              <div className="area-text column">
                <VisibilitySensor once>
                  {({ isVisible }) => (
                    <>
                      <Spring delay={0} to={{ opacity: isVisible ? '1' : '0' }}>
                        {(props) => (
                          <div
                            className={`fade-active ${
                              isVisible ? 'active' : ''
                            }`}
                            style={{ ...props }}
                          >
                            <h6>Interior Design</h6>
                            {/* <p className="text-subtite">For this project the client requested the home to have unobstructed views of the lake.  To meet this requirement, we started with ensuring all upper floor bedrooms, on the western side of the house, would enjoy the stunning lake views.  The master bedroom became a bright and inviting space with floor to ceiling windows which carried through from the bedroom to the modern finished ensuite bath.  To further enhance the views and experience, a walk-out balcony was constructed from the master bedroom to allow the clients to relax and enjoy the evening sunsets.  Through the use of frameless corner windows we were also able to duplicate these views on the main floor for the kitchen and living room.  Finishing off our design was the creation of a contemporary styled detached garage and a functional and stylish outdoor kitchen area in the backyard patio for entertaining family and guests.</p> */}
                          </div>
                        )}
                      </Spring>
                    </>
                  )}
                </VisibilitySensor>
              </div>
            </div>
          </section>

          {/* <section className="content-profile step-one">
                    <div className="container">
                        <div className="area-text column">
                            <VisibilitySensor once>
                                {({ isVisible }) => (
                                    <>
                                    <Spring delay={0} to={{ opacity: isVisible ? '1' : "0" }} >
                                    {props => <div className={`step-portfolio ${isVisible ? "active" : ""}`} style={{ ...props }}></div>}
                                    </Spring>
                                </>
                            )}
                            </VisibilitySensor>
                            <h2 className="subtitle">
                                <VisibilitySensor once>
                                {({ isVisible }) => (
                                    <>
                                    <Spring delay={100} to={{ opacity: isVisible ? '1' : "0", transform: isVisible ? 'translateY(0) rotate(0)' : "translateY(160px) rotate(6deg)" }} >
                                    {props => <div><span style={{ ...props }}>Functional Design </span></div>}
                                    </Spring>
                                </>
                                )}
                            </VisibilitySensor>
                            </h2>

                            <VisibilitySensor once>
                                {({ isVisible }) => (
                                    <>
                                    <Spring delay={300} to={{ opacity: isVisible ? '1' : "0" }} >
                                    {props => <h3 style={{ ...props }}>Our design process is entirely focused on the client. It begins with our initial meeting, that can be in person or over the phone, when we gather all the information about their desires, necessities and expectations for the new project.</h3>}
                                    </Spring>
                                </>
                                )}
                            </VisibilitySensor>
                        </div>
                    </div>
                </section> */}

          <section className="paddImg imagesProfile">
            <div className="square-effect"></div>
            <VisibilitySensor partialVisibility={true} once>
              {({ isVisible }) => (
                <>
                  <Spring
                    delay={100}
                    to={{
                      opacity: isVisible ? '1' : '0',
                      transform: isVisible
                        ? 'translateY(0px)'
                        : 'translateY(300px)',
                    }}
                  >
                    {(props) => (
                      <div
                        className={`small-picture float-image image-right image-effect ${
                          isVisible ? 'active' : ''
                        }`}
                        style={{ ...props }}
                      >
                      <picture>
                            <source
                                media="(max-width: 1170px)"
                                srcSet={interiorPhoto2M}
                                type="image/jpg"
                            />
                            <source
                                media="(min-width: 1171px)"
                                srcSet={interiorPhoto2}
                                type="image/jpg"
                            />
                            <img src={interiorPhoto2} alt="Interior Design" />
                            </picture>
                      </div>
                    )}
                  </Spring>
                </>
              )}
            </VisibilitySensor>
            <div className="container full">
              <div className="area-image column">
                <VisibilitySensor partialVisibility={true} once>
                  {({ isVisible }) => (
                    <>
                      <Spring
                        delay={0}
                        to={{
                          opacity: isVisible ? '1' : '0',
                          transform: isVisible
                            ? 'translateY(0px)'
                            : 'translateY(300px)',
                        }}
                      >
                        {(props) => (
                          <div
                            className={`image-effect ${
                              isVisible ? 'active' : ''
                            }`}
                            style={{ ...props }}
                          >
                          <picture>
                                <source
                                    media="(max-width: 1170px)"
                                    srcSet={interiorPhoto3M}
                                    type="image/jpg"
                                />
                                <source
                                    media="(min-width: 1171px)"
                                    srcSet={interiorPhoto3}
                                    type="image/jpg"
                                />
                                <img src={interiorPhoto3} alt="Interior Design" />
                                </picture>
                          </div>
                        )}
                      </Spring>
                    </>
                  )}
                </VisibilitySensor>
              </div>
            </div>
          </section>

          <section className="paddImg imagesProfile">
            <div className="square-effect"></div>
            <VisibilitySensor partialVisibility={true} once>
              {({ isVisible }) => (
                <>
                  <Spring
                    delay={100}
                    to={{
                      opacity: isVisible ? '1' : '0',
                      transform: isVisible
                        ? 'translateY(0px)'
                        : 'translateY(300px)',
                    }}
                  >
                    {(props) => (
                      <div
                        className={`small-picture float-image image-right image-effect ${
                          isVisible ? 'active' : ''
                        }`}
                        style={{ ...props }}
                      >
                      <picture>
                            <source
                                media="(max-width: 1170px)"
                                srcSet={interiorPhoto4M}
                                type="image/jpg"
                            />
                            <source
                                media="(min-width: 1171px)"
                                srcSet={interiorPhoto4}
                                type="image/jpg"
                            />
                            <img src={interiorPhoto4} alt="Interior Design" />
                            </picture>
                      </div>
                    )}
                  </Spring>
                </>
              )}
            </VisibilitySensor>
            <div className="container full">
              <div className="area-image column">
                <VisibilitySensor partialVisibility={true} once>
                  {({ isVisible }) => (
                    <>
                      <Spring
                        delay={0}
                        to={{
                          opacity: isVisible ? '1' : '0',
                          transform: isVisible
                            ? 'translateY(0px)'
                            : 'translateY(300px)',
                        }}
                      >
                        {(props) => (
                          <div
                            className={`image-effect ${
                              isVisible ? 'active' : ''
                            }`}
                            style={{ ...props }}
                          >
                          <picture>
                                <source
                                    media="(max-width: 1170px)"
                                    srcSet={interiorPhoto5M}
                                    type="image/jpg"
                                />
                                <source
                                    media="(min-width: 1171px)"
                                    srcSet={interiorPhoto5}
                                    type="image/jpg"
                                />
                                <img src={interiorPhoto5} alt="Interior Design" />
                                </picture>
                          </div>
                        )}
                      </Spring>
                    </>
                  )}
                </VisibilitySensor>
              </div>
            </div>
          </section>

          <section className="paddImg imagesProfile">
            <div className="square-effect"></div>
            <VisibilitySensor partialVisibility={true} once>
              {({ isVisible }) => (
                <>
                  <Spring
                    delay={100}
                    to={{
                      opacity: isVisible ? '1' : '0',
                      transform: isVisible
                        ? 'translateY(0px)'
                        : 'translateY(300px)',
                    }}
                  >
                    {(props) => (
                      <div
                        className={`small-picture float-image image-right image-effect ${
                          isVisible ? 'active' : ''
                        }`}
                        style={{ ...props }}
                      >
                      <picture>
                            <source
                                media="(max-width: 1170px)"
                                srcSet={interiorPhoto6M}
                                type="image/jpg"
                            />
                            <source
                                media="(min-width: 1171px)"
                                srcSet={interiorPhoto6}
                                type="image/jpg"
                            />
                            <img src={interiorPhoto6} alt="Interior Design" />
                            </picture>
                      </div>
                    )}
                  </Spring>
                </>
              )}
            </VisibilitySensor>
            <div className="container full">
              <div className="area-image column">
                <VisibilitySensor partialVisibility={true} once>
                  {({ isVisible }) => (
                    <>
                      <Spring
                        delay={0}
                        to={{
                          opacity: isVisible ? '1' : '0',
                          transform: isVisible
                            ? 'translateY(0px)'
                            : 'translateY(300px)',
                        }}
                      >
                        {(props) => (
                          <div
                            className={`image-effect ${
                              isVisible ? 'active' : ''
                            }`}
                            style={{ ...props }}
                          >
                          <picture>
                                <source
                                    media="(max-width: 1170px)"
                                    srcSet={interiorPhoto7M}
                                    type="image/jpg"
                                />
                                <source
                                    media="(min-width: 1171px)"
                                    srcSet={interiorPhoto7}
                                    type="image/jpg"
                                />
                                <img src={interiorPhoto7} alt="Interior Design" />
                                </picture>
                          </div>
                        )}
                      </Spring>
                    </>
                  )}
                </VisibilitySensor>
              </div>
            </div>
          </section>

          {/* <section className="content-profile step-two">
                    <div className="container">
                        <div className="area-text column">
                            
                        <VisibilitySensor once>
                                {({ isVisible }) => (
                                    <>
                                    <Spring delay={0} to={{ opacity: isVisible ? '1' : "0" }} >
                                    {props => <div className={`step-portfolio ${isVisible ? "active" : ""}`}></div>}
                                    </Spring>
                                </>
                            )}
                            </VisibilitySensor>
                            <h2 className="subtitle">
                                <VisibilitySensor once>
                                {({ isVisible }) => (
                                    <>
                                    <Spring delay={100} to={{ opacity: isVisible ? '1' : "0", transform: isVisible ? 'translateY(0) rotate(0)' : "translateY(160px) rotate(6deg)" }} >
                                    {props => <div><span style={{ ...props }}>Conceptualization </span></div>}
                                    </Spring>
                                </>
                                )}
                            </VisibilitySensor>
                            </h2>

                            <VisibilitySensor once>
                                {({ isVisible }) => (
                                    <>
                                    <Spring delay={300} to={{ opacity: isVisible ? '1' : "0" }} >
                                    {props => <h3 style={{ ...props }}>After that, we start the design process uniting this information from the client with the principles of functionality, aesthetics, innovation and comfort. Furthermore, we also consider the exceptional features of the site location and all the legal and zoning requirements.</h3>}
                                    </Spring>
                                </>
                                )}
                            </VisibilitySensor>
                        </div>
                    </div>
                </section> */}

          <section className="paddImg imagesProfile float-left">
            <div className="container full">
              <div className="area-image column">
                <VisibilitySensor partialVisibility={true} once>
                  {({ isVisible }) => (
                    <>
                      <Spring
                        delay={0}
                        to={{
                          opacity: isVisible ? '1' : '0',
                          transform: isVisible
                            ? 'translateY(0px)'
                            : 'translateY(300px)',
                        }}
                      >
                        {(props) => (
                          <div
                            className={`image-effect ${
                              isVisible ? 'active' : ''
                            }`}
                            style={{ ...props }}
                          >
                          <picture>
                                <source
                                    media="(max-width: 1170px)"
                                    srcSet={interiorPhoto8M}
                                    type="image/jpg"
                                />
                                <source
                                    media="(min-width: 1171px)"
                                    srcSet={interiorPhoto8}
                                    type="image/jpg"
                                />
                                <img src={interiorPhoto8} alt="Interior Design" />
                                </picture>
                          </div>
                        )}
                      </Spring>
                    </>
                  )}
                </VisibilitySensor>
              </div>
            </div>
          </section>
        </div>

        <div className="area-black float-area">
          {/* <section className="content-profile step-three">
                    <div className="container">
                        <div className="area-text column">
                            <VisibilitySensor once>
                                {({ isVisible }) => (
                                    <>
                                    <Spring delay={0} to={{ opacity: isVisible ? '1' : "0" }} >
                                    {props => <div className={`step-portfolio ${isVisible ? "active" : ""}`} style={{ ...props }}></div>}
                                    </Spring>
                                </>
                            )}
                            </VisibilitySensor>
                            <h2 className="subtitle">
                                <VisibilitySensor once>
                                {({ isVisible }) => (
                                    <>
                                    <Spring delay={100} to={{ opacity: isVisible ? '1' : "0", transform: isVisible ? 'translateY(0) rotate(0)' : "translateY(160px) rotate(6deg)" }} >
                                    {props => <div><span style={{ ...props }}>Creation </span></div>}
                                    </Spring>
                                </>
                                )}
                            </VisibilitySensor>
                            </h2>

                            <VisibilitySensor once>
                                {({ isVisible }) => (
                                    <>
                                    <Spring delay={300} to={{ opacity: isVisible ? '1' : "0" }} >
                                    {props => <h3 style={{ ...props }}>We then present this first study to the client and evolve together to the final project. From there we will start the production of technical drawings and documents for the applicable permits.</h3>}
                                    </Spring>
                                </>
                                )}
                            </VisibilitySensor>
                        </div>
                    </div>
                </section> */}

          <section className=" imagesProfile float-left">
            <VisibilitySensor partialVisibility={true} once>
              {({ isVisible }) => (
                <>
                  <Spring
                    delay={0}
                    to={{
                      opacity: isVisible ? '1' : '0',
                      transform: isVisible
                        ? 'translateY(0px)'
                        : 'translateY(300px)',
                    }}
                  >
                    {(props) => (
                      <div
                        className={`small-picture float-image image-left image-effect black-effect ${
                          isVisible ? 'active' : ''
                        }`}
                        style={{ ...props }}
                      >
                      <picture>
                            <source
                                media="(max-width: 1170px)"
                                srcSet={interiorPhoto9M}
                                type="image/jpg"
                            />
                            <source
                                media="(min-width: 1171px)"
                                srcSet={interiorPhoto9}
                                type="image/jpg"
                            />
                            <img src={interiorPhoto9} alt="Interior Design" />
                            </picture>
                      </div>
                    )}
                  </Spring>
                </>
              )}
            </VisibilitySensor>
            <div className="container full">
              <div className="area-image column">
                <VisibilitySensor partialVisibility={true} once>
                  {({ isVisible }) => (
                    <>
                      <Spring
                        delay={0}
                        to={{
                          opacity: isVisible ? '1' : '0',
                          transform: isVisible
                            ? 'translateY(0px)'
                            : 'translateY(300px)',
                        }}
                      >
                        {(props) => (
                          <div
                            className={`image-effect black-effect ${
                              isVisible ? 'active' : ''
                            }`}
                            style={{ ...props }}
                          >
                          <picture>
                                <source
                                    media="(max-width: 1170px)"
                                    srcSet={interiorPhoto10M}
                                    type="image/jpg"
                                />
                                <source
                                    media="(min-width: 1171px)"
                                    srcSet={interiorPhoto10}
                                    type="image/jpg"
                                />
                                <img src={interiorPhoto10} alt="Interior Design" />
                                </picture>
                          </div>
                        )}
                      </Spring>
                    </>
                  )}
                </VisibilitySensor>
              </div>
            </div>
          </section>
          <Footer />
        </div>
      </>
    );
  }

  _onReady(event) {
    event.target.pauseVideo();
    event.target.mute();
    setTimeout(() => event.target.playVideo(), 20);
  }
}

export default PortfolioCaseStudy15;
