import React, { Component, Fragment } from 'react'
import { Spring } from 'react-spring/renderprops'
// import { Link } from 'react-router-dom'
import VisibilitySensor from '../components/visibility-sensor'
import YouTube from 'react-youtube'

import IMGjohnsson from '../assets/img/profile-johnsson@2x.png'
import IMGjohnssonMW from '../assets/img/profile-johnsson.png'

import IMGMaya from '../assets/img/profile-maya@2x.png'
import IMGMayaMW from '../assets/img/profile-maya.png'

import IMGnorthon from '../assets/img/profile-northon@2x.png'
import IMGnorthonMW from '../assets/img/profile-northon.png'

import IMGRapha from '../assets/img/profile-rapha@2x.png'
import IMGRaphaMW from '../assets/img/profile-rapha.png'

import IMGNathalia from '../assets/img/profile-nathalia@2x.png'
import IMGNathaliaMW from '../assets/img/profile-nathalia.png'

import IMGSilvia from '../assets/img/profile-silvia@2x.png'
import IMGSilviaMW from '../assets/img/profile-silvia.png'

import CarouselProject from '../components/carousel-menu'

import Footer from '../components/footer'

import caseStudy1 from '../assets/img/mobile/949Mississauga/1.jpg'
import caseStudy2 from '../assets/img/mobile/7176Puslinch/1.jpg'
import caseStudy3 from '../assets/img/mobile/135StoneyCreek/1.jpg'
import caseStudy4 from '../assets/img/mobile/109Oakville/1.jpg'
import caseStudy5 from '../assets/img/mobile/26Markham/1.jpg'
import caseStudy6 from '../assets/img/mobile/617Mississauga/1.jpg'
import caseStudy7 from '../assets/img/mobile/10193Bolton/1.jpg'
import caseStudy8 from '../assets/img/mobile/2StoneyCreek/1.jpg'
import caseStudy9 from '../assets/img/mobile/233PrinceEdwardCounty/1.jpg'
import caseStudy10 from '../assets/img/mobile/533Vaughan/1.jpg'

const slides = [
	{
		title: '949 Mississauga',
		image: caseStudy1,
		link: '/portfolio/949-mississauga'
	},
	{
		title: '7176 Puslinch',
		image: caseStudy2,
		link: '/portfolio/7176-puslinch'
	},
	{
		title: '135 Stoney Creek',
		image: caseStudy3,
		link: '/portfolio/135-stoneycreek'
	},
	{
		title: '109 Oakville',
		image: caseStudy4,
		link: '/portfolio/109-oakville'
	},
	{
		title: '26 Markham',
		image: caseStudy5,
		link: '/portfolio/26-markham'
	},
	{
		title: '617 Mississauga',
		image: caseStudy6,
		link: '/portfolio/617-mississauga'
	},
	{
		title: '10193 Bolton',
		image: caseStudy7,
		link: '/portfolio/10193-bolton'
	},
	{
		title: '2 Stoney Creek',
		image: caseStudy8,
		link: '/portfolio/2-stoneycreek'
	},
	{
		title: '233 Prince Edward County',
		image: caseStudy9,
		link: '/portfolio/233-princeedwardcounty'
	},
	{
		title: '533 Vaughan',
		image: caseStudy10,
		link: '/portfolio/533-vaughan'
	},
]

class About extends Component {

	constructor(props) {
		super(props);
		this.state = {
			active: true
		};
	}

	componentDidMount() {
		document.title = "About • Johnsson Studio"
		window.scrollTo(0, 0)
		this.props.menuClose()
	}


	render() {
		const opts = {
			height: '390',
			width: '640',
			playerVars: {
				autoplay: 1,
				controls: 1,
				mute: 1,
				rel: 0
			}
		}

		return (
			<>
				<div id="about">
					<section id="imageFeaturedProfile" className="no-top">
						<div className="container full-image">
							<VisibilitySensor partialVisibility={true} once>
								{({ isVisible }) => (
									<>
										<Spring delay={1000} to={{ opacity: isVisible ? '1' : "0", transform: isVisible ? 'translateY(0px)' : "translateY(300px)" }} >
											{props =>
												<div className="video-container"><div className={`area-video ${isVisible ? "active" : ""}`} delay={1000} to={{ opacity: isVisible ? '1' : "0", transform: isVisible ? 'translateY(0px)' : "translateY(300px)" }} > <YouTube videoId="5F2pzMAGr1g" opts={opts} onReady={this._onReady} />  </div> </div>}
										</Spring>
									</>
								)}
							</VisibilitySensor>
							<div id="header-Featured" className="area-text column">
								<div className="">
									<p className=" font-space" style={{ lineHeight: 1.6 }}>Jeronimo's designs and passion
										marry the principles of functionality,
										aesthetics, innovation, and comfort.
										When architecture works, it considers
										the exceptional features of the site
										location and builds beauty and
										ergonomics into the landscape.
										But the Johnsson Studio ethos
										demands that the client's desires,
										necessities, and expectations guide
										and inspire each project.
										Brazilian-born, Jeronimo graduated
										in 2010 with a Bachelor's Degree in
										Architecture and Urban Planning.
										With clients in Portugal, Mexico,
										Brazil, Turkey, Canada, and USA,
										he loves mixing the best of his
										cultural influences while conforming
										to the layout of the country in which
										he works. He has an uncanny
										ability to adapt, creating unique,
										contemporary, breathtaking projects.
										His mission is not only to create and
										facilitate stunning projects but to
										improve lives. A father of four,
										Jeronimo is intimately aware of the
										vital importance of a home as part
										of the family dynamic. </p>
								</div>
							</div>
						</div>
					</section >
					<section className="about">
						<div className="container full">
							<div className="area-about column">
								<h2 className="subtitle center">Our Team</h2>
								<div id="team-area">
									<div className="item-team">
										<VisibilitySensor partialVisibility={true} once>
											{({ isVisible }) => (
												<>
													<Spring delay={0} to={{ opacity: isVisible ? '1' : "0", transform: isVisible ? 'translateY(0px)' : "translateY(100px)" }} >
														{props =>
															<div className={`picture-about image-effect ${isVisible ? "active" : ""}`} style={{ ...props }}>
																<picture>
																	<source media="(max-width: 1170px)" srcSet={IMGjohnssonMW} type="image/webp" />
																	<source media="(min-width: 1171px)" srcSet={IMGjohnsson} type="image/png" />
																	<img src={IMGjohnsson} alt="Johnsson Studio" />
																</picture>
																<div className="center">
																	<h6>Jeronimo Johnsson</h6>
																	<p><small className="uppercase">CEO/PRINCIPAL • B. Arch</small></p>
																</div>
															</div>
														}
													</Spring>
												</>
											)}
										</VisibilitySensor>
									</div>
									<div className="item-team">
										<VisibilitySensor partialVisibility={true} once>
											{({ isVisible }) => (
												<>
													<Spring delay={1000} to={{ opacity: isVisible ? '1' : "0", transform: isVisible ? 'translateY(0px)' : "translateY(100px)" }} >
														{props =>
															<div className={`picture-about image-effect ${isVisible ? "active" : ""}`} style={{ ...props }}>
																<picture>
																	<source media="(max-width: 1170px)" srcSet={IMGnorthonMW} type="image/webp" />
																	<source media="(min-width: 1171px)" srcSet={IMGnorthon} type="image/png" />
																	<img src={IMGnorthon} alt="Northon Strapasson" />
																</picture>
																<div className="center">
																	<h6>Northon Strapasson</h6>
																	<p><small className="uppercase">B. Civil Engineering</small></p>
																</div>
															</div>
														}
													</Spring>
												</>
											)}
										</VisibilitySensor>
									</div>
									<div className="item-team">
										<VisibilitySensor partialVisibility={true} once>
											{({ isVisible }) => (
												<>
													<Spring delay={1500} to={{ opacity: isVisible ? '1' : "0", transform: isVisible ? 'translateY(0px)' : "translateY(100px)" }} >
														{props =>
															<div className={`picture-about image-effect ${isVisible ? "active" : ""}`} style={{ ...props }}>
																<picture>
																	<source media="(max-width: 1170px)" srcSet={IMGNathaliaMW} type="image/webp" />
																	<source media="(min-width: 1171px)" srcSet={IMGNathalia} type="image/png" />
																	<img src={IMGNathalia} alt="Nathalia Hara" />
																</picture>
																<div className="center">
																	<h6>Nathalia Hara</h6>
																	<p><small className="uppercase">Design Manager • Interiors</small></p>
																</div>
															</div>
														}
													</Spring>
												</>
											)}
										</VisibilitySensor>
									</div>
								</div>
								<div id="team-area-2">
									<div className="item-team">
										<VisibilitySensor partialVisibility={true} once>
											{({ isVisible }) => (
												<>
													<Spring delay={1500} to={{ opacity: isVisible ? '1' : "0", transform: isVisible ? 'translateY(0px)' : "translateY(100px)" }} >
														{props =>
															<div className={`picture-about image-effect ${isVisible ? "active" : ""}`} style={{ ...props }}>
																<picture>
																	<source media="(max-width: 1170px)" srcSet={IMGMayaMW} type="image/png" />
																	<source media="(min-width: 1171px)" srcSet={IMGMaya} type="image/png" />
																	<img src={IMGMaya} alt="Maya S. Bianchi" />
																</picture>
																<div className="center">
																	<h6>Maya S. Bianchi</h6>
																	<p><small className="uppercase">Permit Coordinator</small></p>
																</div>
															</div>
														}
													</Spring>
												</>
											)}
										</VisibilitySensor>
									</div>
									<div className="item-team">
										<VisibilitySensor partialVisibility={true} once>
											{({ isVisible }) => (
												<>
													<Spring delay={1500} to={{ opacity: isVisible ? '1' : "0", transform: isVisible ? 'translateY(0px)' : "translateY(100px)" }} >
														{props =>
															<div className={`picture-about image-effect ${isVisible ? "active" : ""}`} style={{ ...props }}>
																<picture>
																	<source media="(max-width: 1170px)" srcSet={IMGSilviaMW} type="image/png" />
																	<source media="(min-width: 1171px)" srcSet={IMGSilvia} type="image/png" />
																	<img src={IMGSilvia} alt="Silvia Schaeffer" />
																</picture>
																<div className="center">
																	<h6>Silvia Schaeffer</h6>
																	<p><small className="uppercase"> B. Arch • Interior Designer</small></p>
																</div>
															</div>
														}
													</Spring>
												</>
											)}
										</VisibilitySensor>
									</div>
									<div className="item-team">
										<VisibilitySensor partialVisibility={true} once>
											{({ isVisible }) => (
												<>
													<Spring delay={2000} to={{ opacity: isVisible ? '1' : "0", transform: isVisible ? 'translateY(0px)' : "translateY(100px)" }} >
														{props =>
															<div className={`picture-about image-effect ${isVisible ? "active" : ""}`} style={{ ...props }}>
																<picture>
																	<source media="(max-width: 1170px)" srcSet={IMGRaphaMW} type="image/webp" />
																	<source media="(min-width: 1171px)" srcSet={IMGRapha} type="image/png" />
																	<img src={IMGRapha} alt="Rapha Cruz" />
																</picture>
																<div className="center">
																	<h6>Rapha Cruz</h6>
																	<p><small className="uppercase">Media producer - Post production</small></p>
																</div>
															</div>
														}
													</Spring>
												</>
											)}
										</VisibilitySensor>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section id="projects">
						<h4 className="uppercase">Projects</h4>
						<div id="projects-about">
							<VisibilitySensor partialVisibility={true} once>
								{({ isVisible }) => (
									<>
										<Spring delay={1500} to={{ opacity: isVisible ? '1' : "0", transform: isVisible ? 'translateY(0px)' : "translateY(300px)" }} >
											{props =>
												<div className={`area-carousel-projects ${isVisible ? "active" : ""}`}>
													<CarouselProject slides={slides} />
												</div>
											}
										</Spring>
									</>
								)}
							</VisibilitySensor>
						</div>
					</section>
				</div >
				<Footer />
			</>
		);
	}
}

export default About;
