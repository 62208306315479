import React, { Component } from 'react';
import { Spring } from 'react-spring/renderprops';
import VisibilitySensor from '../../components/visibility-sensor';
import Footer from '../../components/footer';
import YouTube from 'react-youtube';
import caseStudy2 from '../../assets/img/109Oakville/1.jpg';
import caseStudy3 from '../../assets/img/109Oakville/2.jpg';
import caseStudy4 from '../../assets/img/109Oakville/3.jpg';
import caseStudy5 from '../../assets/img/109Oakville/4.jpg';
import caseStudy6 from '../../assets/img/109Oakville/5.jpg';
import caseStudy7 from '../../assets/img/109Oakville/6.jpg';
import caseStudy8 from '../../assets/img/109Oakville/7.jpg';

import caseStudy2M from '../../assets/img/mobile/109Oakville/1.jpg';
import caseStudy3M from '../../assets/img/mobile/109Oakville/2.jpg';
import caseStudy4M from '../../assets/img/mobile/109Oakville/3.jpg';
import caseStudy5M from '../../assets/img/mobile/109Oakville/4.jpg';
import caseStudy6M from '../../assets/img/mobile/109Oakville/5.jpg';
import caseStudy7M from '../../assets/img/mobile/109Oakville/6.jpg';
import caseStudy8M from '../../assets/img/mobile/109Oakville/7.jpg';

class PortfolioCaseStudy4 extends Component {
  componentDidMount() {
    document.title = '109 Oakville • Johnsson Studio';
    window.scrollTo(0, 0);
    this.props.menuClose();
  }

  render() {
    const opts = {
      height: '390',
      width: '640',
      playerVars: {
        autoplay: 1,
        controls: 1,
        mute: 1,
        rel: 0,
      },
    };

    return (
      <>
        <div id="internal-page">
          <section id="imageFeaturedProfile" className="no-top">
            <div className="container full-image">
              <VisibilitySensor partialVisibility={true} once>
                {({ isVisible }) => (
                  <>
                    <Spring
                      delay={1000}
                      to={{
                        opacity: isVisible ? '1' : '0',
                        transform: isVisible
                          ? 'translateY(0px)'
                          : 'translateY(300px)',
                      }}
                    >
                      {(props) => (
                        <div className="video-container">
                          <div
                            className={`area-video ${
                              isVisible ? 'active' : ''
                            }`}
                            delay={1000}
                            to={{
                              opacity: isVisible ? '1' : '0',
                              transform: isVisible
                                ? 'translateY(0px)'
                                : 'translateY(300px)',
                            }}
                          >
                            {' '}
                            <YouTube
                              videoId="LGwt-UMgGV8"
                              opts={opts}
                              onReady={this._onReady}
                            />{' '}
                          </div>{' '}
                        </div>
                      )}
                    </Spring>
                  </>
                )}
              </VisibilitySensor>
              <div className="area-text column">
                <VisibilitySensor once>
                  {({ isVisible }) => (
                    <>
                      <Spring delay={0} to={{ opacity: isVisible ? '1' : '0' }}>
                        {(props) => (
                          <div
                            className={`fade-active ${
                              isVisible ? 'active' : ''
                            }`}
                            style={{ ...props }}
                          >
                            <h6>109 Oakville</h6>
                            <p className="text-subtite">
                              The owner of this new house in Oakville ON
                              requested a contemporary design for both the
                              exterior and interior. His requirements included
                              separate spaces for entertaining that could be
                              connected and accessed through the exterior and
                              interior of the home.
                            </p>
                          </div>
                        )}
                      </Spring>
                    </>
                  )}
                </VisibilitySensor>
              </div>
            </div>
          </section>

          {/* <section className="content-profile step-one">
                    <div className="container">
                        <div className="area-text column">
                            <VisibilitySensor once>
                                {({ isVisible }) => (
                                    <>
                                    <Spring delay={0} to={{ opacity: isVisible ? '1' : "0" }} >
                                    {props => <div className={`step-portfolio ${isVisible ? "active" : ""}`} style={{ ...props }}></div>}
                                    </Spring>
                                </>
                            )}
                            </VisibilitySensor>
                            <h2 className="subtitle">
                                <VisibilitySensor once>
                                {({ isVisible }) => (
                                    <>
                                    <Spring delay={100} to={{ opacity: isVisible ? '1' : "0", transform: isVisible ? 'translateY(0) rotate(0)' : "translateY(160px) rotate(6deg)" }} >
                                    {props => <div><span style={{ ...props }}>Functional Design </span></div>}
                                    </Spring>
                                </>
                                )}
                            </VisibilitySensor>
                            </h2>

                            <VisibilitySensor once>
                                {({ isVisible }) => (
                                    <>
                                    <Spring delay={300} to={{ opacity: isVisible ? '1' : "0" }} >
                                    {props => <h3 style={{ ...props }}>Our design process is entirely focused on the client. It begins with our initial meeting, that can be in person or over the phone, when we gather all the information about their desires, necessities and expectations for the new project.</h3>}
                                    </Spring>
                                </>
                                )}
                            </VisibilitySensor>
                        </div>
                    </div>
                </section> */}

          <section className="paddImg imagesProfile">
            <div className="square-effect"></div>
            <VisibilitySensor partialVisibility={true} once>
              {({ isVisible }) => (
                <>
                  <Spring
                    delay={100}
                    to={{
                      opacity: isVisible ? '1' : '0',
                      transform: isVisible
                        ? 'translateY(0px)'
                        : 'translateY(300px)',
                    }}
                  >
                    {(props) => (
                      <div
                        className={`small-picture float-image image-right image-effect ${
                          isVisible ? 'active' : ''
                        }`}
                        style={{ ...props }}
                      >
                        <picture>
                          <source
                            media="(max-width: 1170px)"
                            srcSet={caseStudy2M}
                            type="image/jpg"
                          />
                          <source
                            media="(min-width: 1171px)"
                            srcSet={caseStudy2}
                            type="image/jpg"
                          />
                          <img src={caseStudy2} alt="109 Oakville" />
                        </picture>
                      </div>
                    )}
                  </Spring>
                </>
              )}
            </VisibilitySensor>
            <div className="container full">
              <div className="area-image column">
                <VisibilitySensor partialVisibility={true} once>
                  {({ isVisible }) => (
                    <>
                      <Spring
                        delay={0}
                        to={{
                          opacity: isVisible ? '1' : '0',
                          transform: isVisible
                            ? 'translateY(0px)'
                            : 'translateY(300px)',
                        }}
                      >
                        {(props) => (
                          <div
                            className={`image-effect ${
                              isVisible ? 'active' : ''
                            }`}
                            style={{ ...props }}
                          >
                            <picture>
                              <source
                                media="(max-width: 1170px)"
                                srcSet={caseStudy3M}
                                type="image/jpg"
                              />
                              <source
                                media="(min-width: 1171px)"
                                srcSet={caseStudy3}
                                type="image/jpg"
                              />
                              <img src={caseStudy3} alt="109 Oakville" />
                            </picture>
                          </div>
                        )}
                      </Spring>
                    </>
                  )}
                </VisibilitySensor>
              </div>
            </div>
          </section>

          <section className="paddImg imagesProfile float-left">
            <div className="container full">
              <div className="area-image column">
                <VisibilitySensor partialVisibility={true} once>
                  {({ isVisible }) => (
                    <>
                      <Spring
                        delay={0}
                        to={{
                          opacity: isVisible ? '1' : '0',
                          transform: isVisible
                            ? 'translateY(0px)'
                            : 'translateY(300px)',
                        }}
                      >
                        {(props) => (
                          <div
                            className={`image-effect ${
                              isVisible ? 'active' : ''
                            }`}
                            style={{ ...props }}
                          >
                            <picture>
                              <source
                                media="(max-width: 1170px)"
                                srcSet={caseStudy4M}
                                type="image/jpg"
                              />
                              <source
                                media="(min-width: 1171px)"
                                srcSet={caseStudy4}
                                type="image/jpg"
                              />
                              <img src={caseStudy4} alt="109 Oakville" />
                            </picture>
                          </div>
                        )}
                      </Spring>
                    </>
                  )}
                </VisibilitySensor>
              </div>
            </div>
          </section>

          <section className="paddImg imagesProfile">
            <div className="square-effect"></div>
            <VisibilitySensor partialVisibility={true} once>
              {({ isVisible }) => (
                <>
                  <Spring
                    delay={100}
                    to={{
                      opacity: isVisible ? '1' : '0',
                      transform: isVisible
                        ? 'translateY(0px)'
                        : 'translateY(300px)',
                    }}
                  >
                    {(props) => (
                      <div
                        className={`small-picture float-image image-right image-effect ${
                          isVisible ? 'active' : ''
                        }`}
                        style={{ ...props }}
                      >
                        <picture>
                          <source
                            media="(max-width: 1170px)"
                            srcSet={caseStudy5M}
                            type="image/jpg"
                          />
                          <source
                            media="(min-width: 1171px)"
                            srcSet={caseStudy5}
                            type="image/jpg"
                          />
                          <img src={caseStudy5} alt="109 Oakville" />
                        </picture>
                      </div>
                    )}
                  </Spring>
                </>
              )}
            </VisibilitySensor>
            <div className="container full">
              <div className="area-image column">
                <VisibilitySensor partialVisibility={true} once>
                  {({ isVisible }) => (
                    <>
                      <Spring
                        delay={0}
                        to={{
                          opacity: isVisible ? '1' : '0',
                          transform: isVisible
                            ? 'translateY(0px)'
                            : 'translateY(300px)',
                        }}
                      >
                        {(props) => (
                          <div
                            className={`image-effect ${
                              isVisible ? 'active' : ''
                            }`}
                            style={{ ...props }}
                          >
                            <picture>
                              <source
                                media="(max-width: 1170px)"
                                srcSet={caseStudy6M}
                                type="image/jpg"
                              />
                              <source
                                media="(min-width: 1171px)"
                                srcSet={caseStudy6}
                                type="image/jpg"
                              />
                              <img src={caseStudy6} alt="109 Oakville" />
                            </picture>
                          </div>
                        )}
                      </Spring>
                    </>
                  )}
                </VisibilitySensor>
              </div>
            </div>
          </section>
        </div>

        <div className="area-black float-area">
          <section className="imagesProfile float-left">
            <VisibilitySensor partialVisibility={true} once>
              {({ isVisible }) => (
                <>
                  <Spring
                    delay={0}
                    to={{
                      opacity: isVisible ? '1' : '0',
                      transform: isVisible
                        ? 'translateY(0px)'
                        : 'translateY(300px)',
                    }}
                  >
                    {(props) => (
                      <div
                        className={`small-picture float-image image-left image-effect black-effect ${
                          isVisible ? 'active' : ''
                        }`}
                        style={{ ...props }}
                      >
                        <picture>
                          <source
                            media="(max-width: 1170px)"
                            srcSet={caseStudy7M}
                            type="image/jpg"
                          />
                          <source
                            media="(min-width: 1171px)"
                            srcSet={caseStudy7}
                            type="image/jpg"
                          />
                          <img src={caseStudy7} alt="109 Oakville" />
                        </picture>
                      </div>
                    )}
                  </Spring>
                </>
              )}
            </VisibilitySensor>
            <div className="container full">
              <div className="area-image column">
                <VisibilitySensor partialVisibility={true} once>
                  {({ isVisible }) => (
                    <>
                      <Spring
                        delay={0}
                        to={{
                          opacity: isVisible ? '1' : '0',
                          transform: isVisible
                            ? 'translateY(0px)'
                            : 'translateY(300px)',
                        }}
                      >
                        {(props) => (
                          <div
                            className={`image-effect black-effect ${
                              isVisible ? 'active' : ''
                            }`}
                            style={{ ...props }}
                          >
                            <picture>
                              <source
                                media="(max-width: 1170px)"
                                srcSet={caseStudy8M}
                                type="image/jpg"
                              />
                              <source
                                media="(min-width: 1171px)"
                                srcSet={caseStudy8}
                                type="image/jpg"
                              />
                              <img src={caseStudy8} alt="109 Oakville" />
                            </picture>
                          </div>
                        )}
                      </Spring>
                    </>
                  )}
                </VisibilitySensor>
              </div>
            </div>
          </section>
          <Footer />
        </div>
      </>
    );
  }

  _onReady(event) {
    event.target.pauseVideo();
    event.target.mute();
    setTimeout(() => event.target.playVideo(), 20);
  }
}

export default PortfolioCaseStudy4;
