import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import CarouselMenu from '../components/carousel-menu'

import caseStudy1 from '../assets/img/mobile/949Mississauga/1.jpg'
import caseStudy2 from '../assets/img/mobile/7176Puslinch/1.jpg'
import caseStudy3 from '../assets/img/mobile/135StoneyCreek/1.jpg'
import caseStudy4 from '../assets/img/mobile/109Oakville/1.jpg'
import caseStudy5 from '../assets/img/mobile/26Markham/1.jpg'
import caseStudy6 from '../assets/img/mobile/617Mississauga/1.jpg'
import caseStudy7 from '../assets/img/mobile/10193Bolton/1.jpg'
import caseStudy8 from '../assets/img/mobile/2StoneyCreek/1.jpg'
import caseStudy9 from '../assets/img/mobile/233PrinceEdwardCounty/1.jpg'
import caseStudy10 from '../assets/img/mobile/533Vaughan/1.jpg'

const slides = [
  {
    title: '949 Mississauga',
    image: caseStudy1,
    link: '/portfolio/949-mississauga'
  },
  {
    title: '7176 Puslinch',
    image: caseStudy2,
    link: '/portfolio/7176-puslinch'
  },
  {
    title: '135 Stoney Creek',
    image: caseStudy3,
    link: '/portfolio/135-stoneycreek'
  },
  {
    title: '109 Oakville',
    image: caseStudy4,
    link: '/portfolio/109-oakville'
  },
  {
    title: '26 Markham',
    image: caseStudy5,
    link: '/portfolio/26-markham'
  },
  {
    title: '617 Mississauga',
    image: caseStudy6,
    link: '/portfolio/617-mississauga'
  },
  {
    title: '10193 Bolton',
    image: caseStudy7,
    link: '/portfolio/10193-bolton'
  },
  {
    title: '2 Stoney Creek',
    image: caseStudy8,
    link: '/portfolio/2-stoneycreek'
  },
  {
    title: '233 Prince Edward County',
    image: caseStudy9,
    link: '/portfolio/233-princeedwardcounty'
  },
  {
    title: '533 Vaughan',
    image: caseStudy10,
    link: '/portfolio/533-vaughan'
  },
]

class Menu extends Component {
  render() {
    return (
      <>
        <div id="menu" className={this.props.menu ? "active" : ""}>
          <div className="area-menu">
            <div className="menu-projects column">
              <ul className="nav">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/about">About</Link>
                </li>
              </ul>
              <h4 className="uppercase">Projects</h4>
              <div id="projects-menu">
                <CarouselMenu slides={slides} />
              </div>

            </div>
            <div className="footer-menu">
              <div className="squares">
                <div className="square-icon"></div>
                <div className="square-icon"></div>
                <div className="square-icon"></div>
              </div>
              <ul>
                <li>
                  <p>Email us at <br /><strong><a href="mailto:contact@johnssonstudio.com">contact@johnssonstudio.com</a></strong></p>
                </li>
                <li>
                  <p>Toronto, ON <br />

                    <a target="_blank" rel="noreferrer" href="https://telegram.me/johnssonstudio">
                      <span className="icon-footer">
                        <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlnsBx="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M1.89095 7.85009C7.24986 5.5153 10.8233 3.97606 12.6113 3.23237C17.7164 1.10901 18.7771 0.740154 19.4686 0.727974C19.6206 0.725295 19.9606 0.762982 20.1809 0.941697C20.3669 1.0926 20.418 1.29645 20.4425 1.43952C20.467 1.58259 20.4975 1.90852 20.4732 2.16319C20.1966 5.06991 18.9996 12.1238 18.3906 15.3794C18.1329 16.7569 17.6255 17.2188 17.1343 17.264C16.0668 17.3622 15.2562 16.5585 14.2222 15.8808C12.6043 14.8202 11.6903 14.16 10.1198 13.1251C8.30488 11.9291 9.48143 11.2717 10.5158 10.1974C10.7865 9.91627 15.4899 5.63808 15.581 5.24999C15.5924 5.20145 15.6029 5.02052 15.4955 4.92498C15.388 4.82945 15.2293 4.86212 15.1148 4.8881C14.9526 4.92493 12.3679 6.63328 7.36093 10.0131C6.62729 10.5169 5.96278 10.7624 5.36741 10.7495C4.71105 10.7353 3.44848 10.3784 2.5099 10.0733C1.35868 9.69908 0.443718 9.50123 0.523391 8.8657C0.56489 8.53468 1.02074 8.19614 1.89095 7.85009Z" fill="white" />
                        </svg>
                      </span>
                    </a>
                    <a target="_blank" rel="noreferrer" href="https://wa.me/+16476782179">

                      <span className="icon-footer">
                        <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlnsBx="http://www.w3.org/2000/svg">
                          <g clipPath="url(#clip0_846_26)" filter="url(#filter0_i_846_26)">
                            <path d="M0.5 18L1.79031 13.2023C0.749708 11.3334 0.419097 9.15247 0.859148 7.05981C1.2992 4.96716 2.48039 3.10311 4.18598 1.80974C5.89157 0.51636 8.00714 -0.119591 10.1445 0.0185802C12.2818 0.156752 14.2976 1.05978 15.8219 2.56195C17.3461 4.06413 18.2767 6.06469 18.4427 8.19652C18.6087 10.3284 17.9992 12.4485 16.7258 14.1678C15.4525 15.8872 13.6008 17.0905 11.5105 17.5568C9.42026 18.0232 7.23164 17.7214 5.34629 16.7068L0.5 18ZM5.57997 14.9114L5.87969 15.0889C7.24529 15.897 8.84029 16.2314 10.4161 16.04C11.992 15.8486 13.4601 15.1422 14.5918 14.0308C15.7235 12.9194 16.4552 11.4655 16.6728 9.89559C16.8903 8.32572 16.5817 6.72808 15.7948 5.35164C15.0079 3.97521 13.7871 2.8973 12.3225 2.28588C10.8579 1.67447 9.23191 1.5639 7.69783 1.9714C6.16374 2.37891 4.80777 3.2816 3.84122 4.5388C2.87467 5.796 2.35183 7.3371 2.35419 8.92192C2.3529 10.236 2.71693 11.5247 3.40574 12.6444L3.5937 12.9538L2.87234 15.6316L5.57997 14.9114Z" fill="white" />
                            <path fillRule="evenodd" clipRule="evenodd" d="M12.8547 10.1538C12.679 10.0124 12.4733 9.91278 12.2532 9.86268C12.0331 9.81259 11.8045 9.8133 11.5847 9.86476C11.2545 10.0017 11.0411 10.519 10.8278 10.7776C10.7828 10.8396 10.7167 10.8831 10.6419 10.8999C10.567 10.9167 10.4886 10.9057 10.4214 10.8689C9.21252 10.3962 8.19924 9.5294 7.54613 8.40922C7.49042 8.33932 7.46406 8.25057 7.47258 8.16165C7.48111 8.07273 7.52387 7.99058 7.59185 7.9325C7.82981 7.69731 8.00453 7.40602 8.09984 7.08555C8.12101 6.73207 8.03989 6.37997 7.86617 6.07124C7.73186 5.63841 7.47626 5.25301 7.12957 4.96057C6.95076 4.88028 6.75249 4.85336 6.55869 4.88306C6.36489 4.91275 6.18384 4.9978 6.03738 5.12793C5.78311 5.34694 5.58129 5.62011 5.44678 5.9273C5.31226 6.2345 5.24849 6.56791 5.26014 6.90297C5.26093 7.09113 5.28481 7.27849 5.33126 7.46084C5.44922 7.89898 5.63063 8.31757 5.86974 8.70337C6.04225 8.99891 6.23048 9.28501 6.43361 9.56047C7.09378 10.4652 7.92362 11.2334 8.87708 11.8224C9.35553 12.1217 9.86689 12.365 10.4011 12.5476C10.956 12.7987 11.5686 12.8951 12.174 12.8265C12.5189 12.7744 12.8457 12.6385 13.1256 12.4306C13.4055 12.2228 13.63 11.9495 13.7792 11.6347C13.8669 11.4446 13.8936 11.232 13.8554 11.0261C13.764 10.6052 13.2001 10.3567 12.8547 10.1538Z" fill="white" />
                          </g>
                          <defs>
                            <filter id="filter0_i_846_26" x="0.5" y="0" width="18" height="18.5" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                              <feFlood floodOpacity="0" result="BackgroundImageFix" />
                              <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                              <feOffset dy="0.5" />
                              <feGaussianBlur stdDeviation="1.25" />
                              <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                              <feBlend mode="normal" in2="shape" result="effect1_innerShadow_846_26" />
                            </filter>
                            <clipPath id="clip0_846_26">
                              <rect width="18" height="18" fill="white" transform="translate(0.5)" />
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                    </a>

                    <a className="link-default" href="tel:647-678-2179"><strong>+1 (647) 678-2179</strong></a></p>
                </li>
                <li>
                  <p>
                    {/* <a className="link-default" href="https://www.youtube.com/c/johnssonstudio" target="blank"><strong>Youtube</strong></a> */}
                    <a className="link-default" href="https://www.instagram.com/johnssonstudio/" target="blank"><strong>Instagram</strong></a>
                    {/* <a className="link-default" href="https://www.linkedin.com/company/johnsson-studio/about/" target="blank"><strong>Linkedin</strong></a> */}
                    <br />/johnssonstudio
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default Menu
