import React, { Component } from 'react';
import { Spring } from 'react-spring/renderprops';
import VisibilitySensor from '../../components/visibility-sensor';
import Footer from '../../components/footer';
import YouTube from 'react-youtube';
import caseStudy2 from '../../assets/img/26Markham/1.jpg';
import caseStudy3 from '../../assets/img/26Markham/2.jpg';
import caseStudy4 from '../../assets/img/26Markham/3.jpg';
import caseStudy5 from '../../assets/img/26Markham/4.jpg';
import caseStudy6 from '../../assets/img/26Markham/5.jpg';

import caseStudy2M from '../../assets/img/mobile/26Markham/1.jpg';
import caseStudy3M from '../../assets/img/mobile/26Markham/2.jpg';
import caseStudy4M from '../../assets/img/mobile/26Markham/3.jpg';
import caseStudy5M from '../../assets/img/mobile/26Markham/4.jpg';
import caseStudy6M from '../../assets/img/mobile/26Markham/5.jpg';

class PortfolioCaseStudy11 extends Component {
  componentDidMount() {
    document.title = '26 Markham • Johnsson Studio';
    window.scrollTo(0, 0);
    this.props.menuClose();
  }

  render() {
    const opts = {
      height: '390',
      width: '640',
      playerVars: {
        autoplay: 1,
        controls: 1,
        mute: 1,
        rel: 0,
      },
    };
    return (
      <>
        <div id="internal-page">
          <section id="imageFeaturedProfile" className="no-top">
            <div className="container full-image">
              <VisibilitySensor partialVisibility={true} once>
                {({ isVisible }) => (
                  <>
                    <Spring
                      delay={1000}
                      to={{
                        opacity: isVisible ? '1' : '0',
                        transform: isVisible
                          ? 'translateY(0px)'
                          : 'translateY(300px)',
                      }}
                    >
                      {(props) => (
                        <div className="video-container">
                          <div
                            className={`area-video ${
                              isVisible ? 'active' : ''
                            }`}
                            delay={1000}
                            to={{
                              opacity: isVisible ? '1' : '0',
                              transform: isVisible
                                ? 'translateY(0px)'
                                : 'translateY(300px)',
                            }}
                          >
                            {' '}
                            <YouTube
                              videoId="ZeLWQSA91y8"
                              opts={opts}
                              onReady={this._onReady}
                            />{' '}
                          </div>{' '}
                        </div>
                      )}
                    </Spring>
                  </>
                )}
              </VisibilitySensor>
              <div className="area-text column">
                <VisibilitySensor once>
                  {({ isVisible }) => (
                    <>
                      <Spring delay={0} to={{ opacity: isVisible ? '1' : '0' }}>
                        {(props) => (
                          <div
                            className={`fade-active ${
                              isVisible ? 'active' : ''
                            }`}
                            style={{ ...props }}
                          >
                            <h6>26 Markham</h6>
                            {/* <p className="text-subtite">For this project the client requested the home to have unobstructed views of the lake.  To meet this requirement, we started with ensuring all upper floor bedrooms, on the western side of the house, would enjoy the stunning lake views.  The master bedroom became a bright and inviting space with floor to ceiling windows which carried through from the bedroom to the modern finished ensuite bath.  To further enhance the views and experience, a walk-out balcony was constructed from the master bedroom to allow the clients to relax and enjoy the evening sunsets.  Through the use of frameless corner windows we were also able to duplicate these views on the main floor for the kitchen and living room.  Finishing off our design was the creation of a contemporary styled detached garage and a functional and stylish outdoor kitchen area in the backyard patio for entertaining family and guests.</p> */}
                          </div>
                        )}
                      </Spring>
                    </>
                  )}
                </VisibilitySensor>
              </div>
            </div>
          </section>

          {/* <section className="content-profile step-one">
                    <div className="container">
                        <div className="area-text column">
                            <VisibilitySensor once>
                                {({ isVisible }) => (
                                    <>
                                    <Spring delay={0} to={{ opacity: isVisible ? '1' : "0" }} >
                                    {props => <div className={`step-portfolio ${isVisible ? "active" : ""}`} style={{ ...props }}></div>}
                                    </Spring>
                                </>
                            )}
                            </VisibilitySensor>
                            <h2 className="subtitle">
                                <VisibilitySensor once>
                                {({ isVisible }) => (
                                    <>
                                    <Spring delay={100} to={{ opacity: isVisible ? '1' : "0", transform: isVisible ? 'translateY(0) rotate(0)' : "translateY(160px) rotate(6deg)" }} >
                                    {props => <div><span style={{ ...props }}>Functional Design </span></div>}
                                    </Spring>
                                </>
                                )}
                            </VisibilitySensor>
                            </h2>

                            <VisibilitySensor once>
                                {({ isVisible }) => (
                                    <>
                                    <Spring delay={300} to={{ opacity: isVisible ? '1' : "0" }} >
                                    {props => <h3 style={{ ...props }}>Our design process is entirely focused on the client. It begins with our initial meeting, that can be in person or over the phone, when we gather all the information about their desires, necessities and expectations for the new project.</h3>}
                                    </Spring>
                                </>
                                )}
                            </VisibilitySensor>
                        </div>
                    </div>
                </section> */}

          <section className="paddImg imagesProfile">
            <div className="square-effect"></div>
            <VisibilitySensor partialVisibility={true} once>
              {({ isVisible }) => (
                <>
                  <Spring
                    delay={100}
                    to={{
                      opacity: isVisible ? '1' : '0',
                      transform: isVisible
                        ? 'translateY(0px)'
                        : 'translateY(300px)',
                    }}
                  >
                    {(props) => (
                      <div
                        className={`small-picture float-image image-right image-effect ${
                          isVisible ? 'active' : ''
                        }`}
                        style={{ ...props }}
                      >
                        <picture>
                          <source
                            media="(max-width: 1170px)"
                            srcSet={caseStudy2M}
                            type="image/jpg"
                          />
                          <source
                            media="(min-width: 1171px)"
                            srcSet={caseStudy2}
                            type="image/jpg"
                          />
                          <img src={caseStudy2} alt="26 Markham" />
                        </picture>
                      </div>
                    )}
                  </Spring>
                </>
              )}
            </VisibilitySensor>
            <div className="container full">
              <div className="area-image column">
                <VisibilitySensor partialVisibility={true} once>
                  {({ isVisible }) => (
                    <>
                      <Spring
                        delay={0}
                        to={{
                          opacity: isVisible ? '1' : '0',
                          transform: isVisible
                            ? 'translateY(0px)'
                            : 'translateY(300px)',
                        }}
                      >
                        {(props) => (
                          <div
                            className={`image-effect ${
                              isVisible ? 'active' : ''
                            }`}
                            style={{ ...props }}
                          >
                            <picture>
                              <source
                                media="(max-width: 1170px)"
                                srcSet={caseStudy3M}
                                type="image/jpg"
                              />
                              <source
                                media="(min-width: 1171px)"
                                srcSet={caseStudy3}
                                type="image/jpg"
                              />
                              <img src={caseStudy3} alt="26 Markham" />
                            </picture>
                          </div>
                        )}
                      </Spring>
                    </>
                  )}
                </VisibilitySensor>
              </div>
            </div>
          </section>

          {/* <section className="content-profile step-two">
                    <div className="container">
                        <div className="area-text column">
                            
                        <VisibilitySensor once>
                                {({ isVisible }) => (
                                    <>
                                    <Spring delay={0} to={{ opacity: isVisible ? '1' : "0" }} >
                                    {props => <div className={`step-portfolio ${isVisible ? "active" : ""}`}></div>}
                                    </Spring>
                                </>
                            )}
                            </VisibilitySensor>
                            <h2 className="subtitle">
                                <VisibilitySensor once>
                                {({ isVisible }) => (
                                    <>
                                    <Spring delay={100} to={{ opacity: isVisible ? '1' : "0", transform: isVisible ? 'translateY(0) rotate(0)' : "translateY(160px) rotate(6deg)" }} >
                                    {props => <div><span style={{ ...props }}>Conceptualization </span></div>}
                                    </Spring>
                                </>
                                )}
                            </VisibilitySensor>
                            </h2>

                            <VisibilitySensor once>
                                {({ isVisible }) => (
                                    <>
                                    <Spring delay={300} to={{ opacity: isVisible ? '1' : "0" }} >
                                    {props => <h3 style={{ ...props }}>After that, we start the design process uniting this information from the client with the principles of functionality, aesthetics, innovation and comfort. Furthermore, we also consider the exceptional features of the site location and all the legal and zoning requirements.</h3>}
                                    </Spring>
                                </>
                                )}
                            </VisibilitySensor>
                        </div>
                    </div>
                </section> */}

          <section className="paddImg imagesProfile float-left">
            <div className="container full">
              <div className="area-image column">
                <VisibilitySensor partialVisibility={true} once>
                  {({ isVisible }) => (
                    <>
                      <Spring
                        delay={0}
                        to={{
                          opacity: isVisible ? '1' : '0',
                          transform: isVisible
                            ? 'translateY(0px)'
                            : 'translateY(300px)',
                        }}
                      >
                        {(props) => (
                          <div
                            className={`image-effect ${
                              isVisible ? 'active' : ''
                            }`}
                            style={{ ...props }}
                          >
                            <picture>
                              <source
                                media="(max-width: 1170px)"
                                srcSet={caseStudy4M}
                                type="image/jpg"
                              />
                              <source
                                media="(min-width: 1171px)"
                                srcSet={caseStudy4}
                                type="image/jpg"
                              />
                              <img src={caseStudy4} alt="26 Markham" />
                            </picture>
                          </div>
                        )}
                      </Spring>
                    </>
                  )}
                </VisibilitySensor>
              </div>
            </div>
          </section>
        </div>

        <div className="area-black float-area">
          {/* <section className="content-profile step-three">
                    <div className="container">
                        <div className="area-text column">
                            <VisibilitySensor once>
                                {({ isVisible }) => (
                                    <>
                                    <Spring delay={0} to={{ opacity: isVisible ? '1' : "0" }} >
                                    {props => <div className={`step-portfolio ${isVisible ? "active" : ""}`} style={{ ...props }}></div>}
                                    </Spring>
                                </>
                            )}
                            </VisibilitySensor>
                            <h2 className="subtitle">
                                <VisibilitySensor once>
                                {({ isVisible }) => (
                                    <>
                                    <Spring delay={100} to={{ opacity: isVisible ? '1' : "0", transform: isVisible ? 'translateY(0) rotate(0)' : "translateY(160px) rotate(6deg)" }} >
                                    {props => <div><span style={{ ...props }}>Creation </span></div>}
                                    </Spring>
                                </>
                                )}
                            </VisibilitySensor>
                            </h2>

                            <VisibilitySensor once>
                                {({ isVisible }) => (
                                    <>
                                    <Spring delay={300} to={{ opacity: isVisible ? '1' : "0" }} >
                                    {props => <h3 style={{ ...props }}>We then present this first study to the client and evolve together to the final project. From there we will start the production of technical drawings and documents for the applicable permits.</h3>}
                                    </Spring>
                                </>
                                )}
                            </VisibilitySensor>
                        </div>
                    </div>
                </section> */}

          <section className=" imagesProfile float-left">
            <VisibilitySensor partialVisibility={true} once>
              {({ isVisible }) => (
                <>
                  <Spring
                    delay={0}
                    to={{
                      opacity: isVisible ? '1' : '0',
                      transform: isVisible
                        ? 'translateY(0px)'
                        : 'translateY(300px)',
                    }}
                  >
                    {(props) => (
                      <div
                        className={`small-picture float-image image-left image-effect black-effect ${
                          isVisible ? 'active' : ''
                        }`}
                        style={{ ...props }}
                      >
                        <picture>
                          <source
                            media="(max-width: 1170px)"
                            srcSet={caseStudy5M}
                            type="image/jpg"
                          />
                          <source
                            media="(min-width: 1171px)"
                            srcSet={caseStudy5}
                            type="image/jpg"
                          />
                          <img src={caseStudy5} alt="26 Markham" />
                        </picture>
                      </div>
                    )}
                  </Spring>
                </>
              )}
            </VisibilitySensor>
            <div className="container full">
              <div className="area-image column">
                <VisibilitySensor partialVisibility={true} once>
                  {({ isVisible }) => (
                    <>
                      <Spring
                        delay={0}
                        to={{
                          opacity: isVisible ? '1' : '0',
                          transform: isVisible
                            ? 'translateY(0px)'
                            : 'translateY(300px)',
                        }}
                      >
                        {(props) => (
                          <div
                            className={`image-effect black-effect ${
                              isVisible ? 'active' : ''
                            }`}
                            style={{ ...props }}
                          >
                            <picture>
                              <source
                                media="(max-width: 1170px)"
                                srcSet={caseStudy6M}
                                type="image/jpg"
                              />
                              <source
                                media="(min-width: 1171px)"
                                srcSet={caseStudy6}
                                type="image/jpg"
                              />
                              <img src={caseStudy6} alt="26 Markham" />
                            </picture>
                          </div>
                        )}
                      </Spring>
                    </>
                  )}
                </VisibilitySensor>
              </div>
            </div>
          </section>
          <Footer />
        </div>
      </>
    );
  }

  _onReady(event) {
    event.target.pauseVideo();
    event.target.mute();
    setTimeout(() => event.target.playVideo(), 20);
  }
}

export default PortfolioCaseStudy11;
