import React, { Component } from 'react';
import { Spring } from 'react-spring/renderprops';
import VisibilitySensor from '../../components/visibility-sensor';
import Footer from '../../components/footer';
// import YouTube from 'react-youtube'
import ProjectsTurkey1 from '../../assets/img/ProjectsTurkey/1.jpg';
import ProjectsTurkey2 from '../../assets/img/ProjectsTurkey/2.jpg';
import ProjectsTurkey3 from '../../assets/img/ProjectsTurkey/3.jpg';
import ProjectsTurkey4 from '../../assets/img/ProjectsTurkey/4.jpg';
import ProjectsTurkey5 from '../../assets/img/ProjectsTurkey/5.jpg';
import ProjectsTurkey6 from '../../assets/img/ProjectsTurkey/6.jpg';
import ProjectsTurkey8 from '../../assets/img/ProjectsTurkey/8.jpg';
import ProjectsTurkey9 from '../../assets/img/ProjectsTurkey/9.jpg';
import ProjectsTurkey10 from '../../assets/img/ProjectsTurkey/10.jpg';
import ProjectsTurkey11 from '../../assets/img/ProjectsTurkey/11.jpg';

import ProjectsTurkey1M from '../../assets/img/mobile/ProjectsTurkey/1.jpg';
import ProjectsTurkey2M from '../../assets/img/mobile/ProjectsTurkey/2.jpg';
import ProjectsTurkey3M from '../../assets/img/mobile/ProjectsTurkey/3.jpg';
import ProjectsTurkey4M from '../../assets/img/mobile/ProjectsTurkey/4.jpg';
import ProjectsTurkey5M from '../../assets/img/mobile/ProjectsTurkey/5.jpg';
import ProjectsTurkey6M from '../../assets/img/mobile/ProjectsTurkey/6.jpg';
import ProjectsTurkey8M from '../../assets/img/mobile/ProjectsTurkey/8.jpg';
import ProjectsTurkey9M from '../../assets/img/mobile/ProjectsTurkey/9.jpg';
import ProjectsTurkey10M from '../../assets/img/mobile/ProjectsTurkey/10.jpg';
import ProjectsTurkey11M from '../../assets/img/mobile/ProjectsTurkey/11.jpg';

class PortfolioCaseStudy16 extends Component {
  componentDidMount() {
    document.title = 'Projects Turkey • Johnsson Studio';
    window.scrollTo(0, 0);
    this.props.menuClose();
  }

  render() {
    // const opts = {
    //     height: '390',
    //     width: '640',
    //     playerVars: {
    //       autoplay: 1,
    //       controls: 1,
    //       mute: 1,
    //       rel: 0
    //     }
    // }
    return (
      <>
        <div id="internal-page">
          <section id="imageFeaturedProfile" className="no-top">
            <div className="container full-image">
              <VisibilitySensor partialVisibility={true} once>
                {({ isVisible }) => (
                  <>
                    <Spring
                      delay={1000}
                      to={{
                        opacity: isVisible ? '1' : '0',
                        transform: isVisible
                          ? 'translateY(0px)'
                          : 'translateY(300px)',
                      }}
                    >
                      {(props) => (
                        <div
                          className={`picture-about image-effect image-delay-1 ${
                            isVisible ? 'active' : ''
                          }`}
                          style={{ ...props }}
                        >
                          <picture>
                            <source
                              media="(max-width: 1170px)"
                              srcSet={ProjectsTurkey1M}
                              type="image/jpg"
                            />
                            <source
                              media="(min-width: 1171px)"
                              srcSet={ProjectsTurkey1}
                              type="image/jpg"
                            />
                            <img src={ProjectsTurkey1} alt="Projects Turkey" />
                          </picture>
                        </div>
                      )}
                    </Spring>
                  </>
                )}
              </VisibilitySensor>
              <div className="area-text column">
                <VisibilitySensor once>
                  {({ isVisible }) => (
                    <>
                      <Spring delay={0} to={{ opacity: isVisible ? '1' : '0' }}>
                        {(props) => (
                          <div
                            className={`fade-active ${
                              isVisible ? 'active' : ''
                            }`}
                            style={{ ...props }}
                          >
                            <h6>Projects Turkey</h6>
                          </div>
                        )}
                      </Spring>
                    </>
                  )}
                </VisibilitySensor>
              </div>
            </div>
          </section>

          <section className="paddImg imagesProfile">
            <div className="square-effect"></div>
            <VisibilitySensor partialVisibility={true} once>
              {({ isVisible }) => (
                <>
                  <Spring
                    delay={100}
                    to={{
                      opacity: isVisible ? '1' : '0',
                      transform: isVisible
                        ? 'translateY(0px)'
                        : 'translateY(300px)',
                    }}
                  >
                    {(props) => (
                      <div
                        className={`small-picture float-image image-right image-effect ${
                          isVisible ? 'active' : ''
                        }`}
                        style={{ ...props }}
                      >
                        <picture>
                          <source
                            media="(max-width: 1170px)"
                            srcSet={ProjectsTurkey2M}
                            type="image/jpg"
                          />
                          <source
                            media="(min-width: 1171px)"
                            srcSet={ProjectsTurkey2}
                            type="image/jpg"
                          />
                          <img src={ProjectsTurkey2} alt="Projects Turkey" />
                        </picture>
                      </div>
                    )}
                  </Spring>
                </>
              )}
            </VisibilitySensor>
            <div className="container full">
              <div className="area-image column">
                <VisibilitySensor partialVisibility={true} once>
                  {({ isVisible }) => (
                    <>
                      <Spring
                        delay={0}
                        to={{
                          opacity: isVisible ? '1' : '0',
                          transform: isVisible
                            ? 'translateY(0px)'
                            : 'translateY(300px)',
                        }}
                      >
                        {(props) => (
                          <div
                            className={`image-effect ${
                              isVisible ? 'active' : ''
                            }`}
                            style={{ ...props }}
                          >
                            <picture>
                              <source
                                media="(max-width: 1170px)"
                                srcSet={ProjectsTurkey3M}
                                type="image/jpg"
                              />
                              <source
                                media="(min-width: 1171px)"
                                srcSet={ProjectsTurkey3}
                                type="image/jpg"
                              />
                              <img
                                src={ProjectsTurkey3}
                                alt="Projects Turkey"
                              />
                            </picture>
                          </div>
                        )}
                      </Spring>
                    </>
                  )}
                </VisibilitySensor>
              </div>
            </div>
          </section>

          <section className="paddImg imagesProfile float-left">
            <div className="container full">
              <div className="area-image column">
                <VisibilitySensor partialVisibility={true} once>
                  {({ isVisible }) => (
                    <>
                      <Spring
                        delay={0}
                        to={{
                          opacity: isVisible ? '1' : '0',
                          transform: isVisible
                            ? 'translateY(0px)'
                            : 'translateY(300px)',
                        }}
                      >
                        {(props) => (
                          <div
                            className={`image-effect ${
                              isVisible ? 'active' : ''
                            }`}
                            style={{ ...props }}
                          >
                            <picture>
                              <source
                                media="(max-width: 1170px)"
                                srcSet={ProjectsTurkey4M}
                                type="image/jpg"
                              />
                              <source
                                media="(min-width: 1171px)"
                                srcSet={ProjectsTurkey4}
                                type="image/jpg"
                              />
                              <img
                                src={ProjectsTurkey4}
                                alt="Projects Turkey"
                              />
                            </picture>
                          </div>
                        )}
                      </Spring>
                    </>
                  )}
                </VisibilitySensor>
              </div>
            </div>
          </section>

          <section className="paddImg imagesProfile">
            <div className="square-effect"></div>
            <VisibilitySensor partialVisibility={true} once>
              {({ isVisible }) => (
                <>
                  <Spring
                    delay={100}
                    to={{
                      opacity: isVisible ? '1' : '0',
                      transform: isVisible
                        ? 'translateY(0px)'
                        : 'translateY(300px)',
                    }}
                  >
                    {(props) => (
                      <div
                        className={`small-picture float-image image-right image-effect ${
                          isVisible ? 'active' : ''
                        }`}
                        style={{ ...props }}
                      >
                        <picture>
                          <source
                            media="(max-width: 1170px)"
                            srcSet={ProjectsTurkey5M}
                            type="image/jpg"
                          />
                          <source
                            media="(min-width: 1171px)"
                            srcSet={ProjectsTurkey5}
                            type="image/jpg"
                          />
                          <img src={ProjectsTurkey5} alt="Projects Turkey" />
                        </picture>
                      </div>
                    )}
                  </Spring>
                </>
              )}
            </VisibilitySensor>
            <div className="container full">
              <div className="area-image column">
                <VisibilitySensor partialVisibility={true} once>
                  {({ isVisible }) => (
                    <>
                      <Spring
                        delay={0}
                        to={{
                          opacity: isVisible ? '1' : '0',
                          transform: isVisible
                            ? 'translateY(0px)'
                            : 'translateY(300px)',
                        }}
                      >
                        {(props) => (
                          <div
                            className={`image-effect ${
                              isVisible ? 'active' : ''
                            }`}
                            style={{ ...props }}
                          >
                            <picture>
                              <source
                                media="(max-width: 1170px)"
                                srcSet={ProjectsTurkey6M}
                                type="image/jpg"
                              />
                              <source
                                media="(min-width: 1171px)"
                                srcSet={ProjectsTurkey6}
                                type="image/jpg"
                              />
                              <img
                                src={ProjectsTurkey6}
                                alt="Projects Turkey"
                              />
                            </picture>
                          </div>
                        )}
                      </Spring>
                    </>
                  )}
                </VisibilitySensor>
              </div>
            </div>
          </section>

          <section className="paddImg imagesProfile float-left">
            <div className="container full">
              <div className="area-image column">
                <VisibilitySensor partialVisibility={true} once>
                  {({ isVisible }) => (
                    <>
                      <Spring
                        delay={0}
                        to={{
                          opacity: isVisible ? '1' : '0',
                          transform: isVisible
                            ? 'translateY(0px)'
                            : 'translateY(300px)',
                        }}
                      >
                        {(props) => (
                          <div
                            className={`image-effect ${
                              isVisible ? 'active' : ''
                            }`}
                            style={{ ...props }}
                          >
                            <picture>
                              <source
                                media="(max-width: 1170px)"
                                srcSet={ProjectsTurkey9M}
                                type="image/jpg"
                              />
                              <source
                                media="(min-width: 1171px)"
                                srcSet={ProjectsTurkey9}
                                type="image/jpg"
                              />
                              <img
                                src={ProjectsTurkey9}
                                alt="Projects Turkey"
                              />
                            </picture>
                          </div>
                        )}
                      </Spring>
                    </>
                  )}
                </VisibilitySensor>
              </div>
            </div>
          </section>

          <section className="paddImg imagesProfile">
            <div className="square-effect"></div>
            <div className="container full">
              <div className="area-image column">
                <VisibilitySensor partialVisibility={true} once>
                  {({ isVisible }) => (
                    <>
                      <Spring
                        delay={0}
                        to={{
                          opacity: isVisible ? '1' : '0',
                          transform: isVisible
                            ? 'translateY(0px)'
                            : 'translateY(300px)',
                        }}
                      >
                        {(props) => (
                          <div
                            className={`image-effect ${
                              isVisible ? 'active' : ''
                            }`}
                            style={{ ...props }}
                          >
                            <picture>
                              <source
                                media="(max-width: 1170px)"
                                srcSet={ProjectsTurkey8M}
                                type="image/jpg"
                              />
                              <source
                                media="(min-width: 1171px)"
                                srcSet={ProjectsTurkey8}
                                type="image/jpg"
                              />
                              <img
                                src={ProjectsTurkey8}
                                alt="Projects Turkey"
                              />
                            </picture>
                          </div>
                        )}
                      </Spring>
                    </>
                  )}
                </VisibilitySensor>
              </div>
            </div>
          </section>
        </div>

        <div className="area-black float-area">
          <section className=" imagesProfile float-left">
            <VisibilitySensor partialVisibility={true} once>
              {({ isVisible }) => (
                <>
                  <Spring
                    delay={0}
                    to={{
                      opacity: isVisible ? '1' : '0',
                      transform: isVisible
                        ? 'translateY(0px)'
                        : 'translateY(300px)',
                    }}
                  >
                    {(props) => (
                      <div
                        className={`small-picture float-image image-left image-effect black-effect ${
                          isVisible ? 'active' : ''
                        }`}
                        style={{ ...props }}
                      >
                        <picture>
                          <source
                            media="(max-width: 1170px)"
                            srcSet={ProjectsTurkey10M}
                            type="image/jpg"
                          />
                          <source
                            media="(min-width: 1171px)"
                            srcSet={ProjectsTurkey10}
                            type="image/jpg"
                          />
                          <img src={ProjectsTurkey10} alt="Projects Turkey" />
                        </picture>
                      </div>
                    )}
                  </Spring>
                </>
              )}
            </VisibilitySensor>
            <div className="container full">
              <div className="area-image column">
                <VisibilitySensor partialVisibility={true} once>
                  {({ isVisible }) => (
                    <>
                      <Spring
                        delay={0}
                        to={{
                          opacity: isVisible ? '1' : '0',
                          transform: isVisible
                            ? 'translateY(0px)'
                            : 'translateY(300px)',
                        }}
                      >
                        {(props) => (
                          <div
                            className={`image-effect black-effect ${
                              isVisible ? 'active' : ''
                            }`}
                            style={{ ...props }}
                          >
                            <picture>
                              <source
                                media="(max-width: 1170px)"
                                srcSet={ProjectsTurkey11M}
                                type="image/jpg"
                              />
                              <source
                                media="(min-width: 1171px)"
                                srcSet={ProjectsTurkey11}
                                type="image/jpg"
                              />
                              <img
                                src={ProjectsTurkey11}
                                alt="Projects Turkey"
                              />
                            </picture>
                          </div>
                        )}
                      </Spring>
                    </>
                  )}
                </VisibilitySensor>
              </div>
            </div>
          </section>
          <Footer />
        </div>
      </>
    );
  }

  _onReady(event) {
    event.target.pauseVideo();
    event.target.mute();
    setTimeout(() => event.target.playVideo(), 20);
  }
}

export default PortfolioCaseStudy16;
