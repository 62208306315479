import React, { Component } from 'react';
import { Spring } from 'react-spring/renderprops';
import VisibilitySensor from '../../components/visibility-sensor';
import Footer from '../../components/footer';
import YouTube from 'react-youtube';
import caseStudy2 from '../../assets/img/949Mississauga/1.jpg';
import caseStudy3 from '../../assets/img/949Mississauga/2.jpg';
import caseStudy4 from '../../assets/img/949Mississauga/3.jpg';
import caseStudy5 from '../../assets/img/949Mississauga/4.jpg';
import caseStudy6 from '../../assets/img/949Mississauga/5.jpg';

import caseStudy2M from '../../assets/img/mobile/949Mississauga/1.jpg';
import caseStudy3M from '../../assets/img/mobile/949Mississauga/2.jpg';
import caseStudy4M from '../../assets/img/mobile/949Mississauga/3.jpg';
import caseStudy5M from '../../assets/img/mobile/949Mississauga/4.jpg';
import caseStudy6M from '../../assets/img/mobile/949Mississauga/5.jpg';

class PortfolioCaseStudy2 extends Component {
  componentDidMount() {
    document.title = '949 Mississauga • Johnsson Studio';
    window.scrollTo(0, 0);
    this.props.menuClose();
  }

  render() {
    const opts = {
      height: '390',
      width: '640',
      playerVars: {
        autoplay: 1,
        controls: 1,
        mute: 1,
        rel: 0,
      },
    };

    return (
      <>
        <div id="internal-page">
          <section id="imageFeaturedProfile" className="no-top">
            <div className="container full-image">
              <VisibilitySensor partialVisibility={true} once>
                {({ isVisible }) => (
                  <>
                    <Spring
                      delay={1000}
                      to={{
                        opacity: isVisible ? '1' : '0',
                        transform: isVisible
                          ? 'translateY(0px)'
                          : 'translateY(300px)',
                      }}
                    >
                      {(props) => (
                        <div className="video-container">
                          <div
                            className={`area-video ${
                              isVisible ? 'active' : ''
                            }`}
                            delay={1000}
                            to={{
                              opacity: isVisible ? '1' : '0',
                              transform: isVisible
                                ? 'translateY(0px)'
                                : 'translateY(300px)',
                            }}
                          >
                            {' '}
                            <YouTube
                              videoId="zdOtFk-7s7w"
                              opts={opts}
                              onReady={this._onReady}
                            />{' '}
                          </div>{' '}
                        </div>
                      )}
                    </Spring>
                  </>
                )}
              </VisibilitySensor>
              <div className="area-text column">
                <VisibilitySensor once>
                  {({ isVisible }) => (
                    <>
                      <Spring delay={0} to={{ opacity: isVisible ? '1' : '0' }}>
                        {(props) => (
                          <div
                            className={`fade-active ${
                              isVisible ? 'active' : ''
                            }`}
                            style={{ ...props }}
                          >
                            <h6>949 Mississauga</h6>
                            <p className="text-subtite">
                              The owner’s requirements for this home in
                              Mississauga ON was to create a unique yet
                              contemporary look for his large immediate family.
                              To achieve his desired look we worked with a
                              variety of different structural shapes and
                              lighting effects for the front façade.
                            </p>
                          </div>
                        )}
                      </Spring>
                    </>
                  )}
                </VisibilitySensor>
              </div>
            </div>
          </section>

          <section className="paddImg imagesProfile">
            <div className="square-effect"></div>
            <VisibilitySensor partialVisibility={true} once>
              {({ isVisible }) => (
                <>
                  <Spring
                    delay={100}
                    to={{
                      opacity: isVisible ? '1' : '0',
                      transform: isVisible
                        ? 'translateY(0px)'
                        : 'translateY(300px)',
                    }}
                  >
                    {(props) => (
                      <div
                        className={`small-picture float-image image-right image-effect ${
                          isVisible ? 'active' : ''
                        }`}
                        style={{ ...props }}
                      >
                        <picture>
                          <source
                            media="(max-width: 1170px)"
                            srcSet={caseStudy2M}
                            type="image/jpg"
                          />
                          <source
                            media="(min-width: 1171px)"
                            srcSet={caseStudy2}
                            type="image/jpg"
                          />
                          <img src={caseStudy2} alt="949 Mississauga" />
                        </picture>
                      </div>
                    )}
                  </Spring>
                </>
              )}
            </VisibilitySensor>
            <div className="container full">
              <div className="area-image column">
                <VisibilitySensor partialVisibility={true} once>
                  {({ isVisible }) => (
                    <>
                      <Spring
                        delay={0}
                        to={{
                          opacity: isVisible ? '1' : '0',
                          transform: isVisible
                            ? 'translateY(0px)'
                            : 'translateY(300px)',
                        }}
                      >
                        {(props) => (
                          <div
                            className={`image-effect ${
                              isVisible ? 'active' : ''
                            }`}
                            style={{ ...props }}
                          >
                            <picture>
                              <source
                                media="(max-width: 1170px)"
                                srcSet={caseStudy3M}
                                type="image/jpg"
                              />
                              <source
                                media="(min-width: 1171px)"
                                srcSet={caseStudy3}
                                type="image/jpg"
                              />
                              <img src={caseStudy3} alt="949 Mississauga" />
                            </picture>
                          </div>
                        )}
                      </Spring>
                    </>
                  )}
                </VisibilitySensor>
              </div>
            </div>
          </section>

          <section className="paddImg imagesProfile float-left">
            <div className="container full">
              <div className="area-image column">
                <VisibilitySensor partialVisibility={true} once>
                  {({ isVisible }) => (
                    <>
                      <Spring
                        delay={0}
                        to={{
                          opacity: isVisible ? '1' : '0',
                          transform: isVisible
                            ? 'translateY(0px)'
                            : 'translateY(300px)',
                        }}
                      >
                        {(props) => (
                          <div
                            className={`image-effect ${
                              isVisible ? 'active' : ''
                            }`}
                            style={{ ...props }}
                          >
                            <picture>
                              <source
                                media="(max-width: 1170px)"
                                srcSet={caseStudy4M}
                                type="image/jpg"
                              />
                              <source
                                media="(min-width: 1171px)"
                                srcSet={caseStudy4}
                                type="image/jpg"
                              />
                              <img src={caseStudy4} alt="949 Mississauga" />
                            </picture>
                          </div>
                        )}
                      </Spring>
                    </>
                  )}
                </VisibilitySensor>
              </div>
            </div>
          </section>
        </div>

        <div className="area-black float-area">
          <section className="imagesProfile float-left">
            <VisibilitySensor partialVisibility={true} once>
              {({ isVisible }) => (
                <>
                  <Spring
                    delay={0}
                    to={{
                      opacity: isVisible ? '1' : '0',
                      transform: isVisible
                        ? 'translateY(0px)'
                        : 'translateY(300px)',
                    }}
                  >
                    {(props) => (
                      <div
                        className={`small-picture float-image image-left image-effect black-effect ${
                          isVisible ? 'active' : ''
                        }`}
                        style={{ ...props }}
                      >
                        <picture>
                          <source
                            media="(max-width: 1170px)"
                            srcSet={caseStudy5M}
                            type="image/jpg"
                          />
                          <source
                            media="(min-width: 1171px)"
                            srcSet={caseStudy5}
                            type="image/jpg"
                          />
                          <img src={caseStudy5} alt="949 Mississauga" />
                        </picture>
                      </div>
                    )}
                  </Spring>
                </>
              )}
            </VisibilitySensor>
            <div className="container full">
              <div className="area-image column">
                <VisibilitySensor partialVisibility={true} once>
                  {({ isVisible }) => (
                    <>
                      <Spring
                        delay={0}
                        to={{
                          opacity: isVisible ? '1' : '0',
                          transform: isVisible
                            ? 'translateY(0px)'
                            : 'translateY(300px)',
                        }}
                      >
                        {(props) => (
                          <div
                            className={`image-effect black-effect ${
                              isVisible ? 'active' : ''
                            }`}
                            style={{ ...props }}
                          >
                            <picture>
                              <source
                                media="(max-width: 1170px)"
                                srcSet={caseStudy6M}
                                type="image/jpg"
                              />
                              <source
                                media="(min-width: 1171px)"
                                srcSet={caseStudy6}
                                type="image/jpg"
                              />
                              <img src={caseStudy6} alt="949 Mississauga" />
                            </picture>
                          </div>
                        )}
                      </Spring>
                    </>
                  )}
                </VisibilitySensor>
              </div>
            </div>
          </section>
          <Footer />
        </div>
      </>
    );
  }

  _onReady(event) {
    event.target.pauseVideo();
    event.target.mute();
    setTimeout(() => event.target.playVideo(), 20);
  }
}

export default PortfolioCaseStudy2;
