import React, { Component } from 'react'
import logo from '../assets/img/svg/logo.svg'
import { Link } from 'react-router-dom'
import Menu from './menu'

class Header extends Component {
	render () {
		return (
			<>
				<header className={`header ${window.location.pathname === '/' ? 'header-home': ''} ${this.props.menu ? "active" : ""}`}>
					<div className="logo">
						<Link to="/">
							<img alt="" src={logo} />
						</Link>
					</div>

					<nav className="navbar">
						<button className="navbar-toggler" onClick={(e) => this.props.menuOpen(e)} type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
							<span className="bar bar-item-one"></span>
							<span className="bar bar-item-two"></span>
						</button>
					</nav>
				</header>
				<Menu
					menuOpen={this.props.menu}
					menuClose={this.props.menuClose}
					menu={this.props.menu}
				/>
			</>
		)
	}
}

export default Header