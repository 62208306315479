import React, { Component, Fragment } from 'react'
import { Spring } from 'react-spring/renderprops'
import { Link } from 'react-router-dom'
import VisibilitySensor from '../components/visibility-sensor'
import caseStudy1 from '../assets/img/949Mississauga/1.jpg'
import caseStudy2 from '../assets/img/7176Puslinch/1.jpg'
import caseStudy3 from '../assets/img/135StoneyCreek/1.jpg'
import caseStudy9 from '../assets/img/26Markham/1.jpg'
import caseStudy4 from '../assets/img/109Oakville/1.jpg'
import caseStudy8 from '../assets/img/617Mississauga/1.jpg'
import caseStudy10 from '../assets/img/10193Bolton/1.jpg'
import caseStudy7 from '../assets/img/2StoneyCreek/1.jpg'
import caseStudy11 from '../assets/img/233PrinceEdwardCounty/1.jpg'
import caseStudy12 from '../assets/img/533Vaughan/1.jpg'

import interiorPhoto1 from '../assets/img/InteriorDesign/13.jpg'
import projectTurkey from '../assets/img/ProjectsTurkey/1.jpg'

import caseStudy1M from '../assets/img/mobile/949Mississauga/1.jpg'
import caseStudy2M from '../assets/img/mobile/7176Puslinch/1.jpg'
import caseStudy3M from '../assets/img/mobile/135StoneyCreek/1.jpg'
import caseStudy9M from '../assets/img/mobile/26Markham/1.jpg'
import caseStudy4M from '../assets/img/mobile/109Oakville/1.jpg'
import caseStudy8M from '../assets/img/mobile/617Mississauga/1.jpg'
import caseStudy10M from '../assets/img/mobile/10193Bolton/1.jpg'
import caseStudy7M from '../assets/img/mobile/2StoneyCreek/1.jpg'
import caseStudy11M from '../assets/img/mobile/233PrinceEdwardCounty/1.jpg'
import caseStudy12M from '../assets/img/mobile/533Vaughan/1.jpg'

import interiorPhoto1M from '../assets/img/InteriorDesign/13.jpg'
import projectTurkeyM from '../assets/img/ProjectsTurkey/1.jpg'

import Footer from '../components/footer'

class Home extends Component {

  constructor(props) {
    super(props);
    this.state = {
      class: "all",
      active: true,
    };
  }

  componentDidMount() {
    document.title = "Jonhsson Studio"
    window.scrollTo(0, 0)
    this.props.menuClose()
  }

  clickMenu($event) {
    this.setState({
      class: $event
    })
  }

  render() {
    return (
      <>
        <div className="home">

          <section id="banner-home" className="page-home">
            <div className="container">
              <div className="area-banner column">
                <h2>
                  <VisibilitySensor once>
                    {({ isVisible }) => (
                      <>
                        <Spring delay={1000} to={{ opacity: isVisible ? '1' : "0", transform: isVisible ? 'translateY(0) rotate(0)' : "translateY(160px) rotate(6deg)" }} >
                          {props => <div><span style={{ ...props }}>We design unique and</span></div>}
                        </Spring>
                        <Spring delay={1300} to={{ opacity: isVisible ? '1' : "0", transform: isVisible ? 'translateY(0) rotate(0)' : "translateY(180px) rotate(6deg)" }} >
                          {props => <div><span style={{ ...props }}>luxury custom homes</span></div>}
                        </Spring>
                      </>
                    )}
                  </VisibilitySensor>
                </h2>
                <VisibilitySensor once>
                  {({ isVisible }) => (
                    <>
                      <Spring delay={2400} to={{ opacity: isVisible ? '1' : "0" }} >
                        {props => <h3 style={{ ...props }}>Luxury Residential Projects • Contemporary • Creative</h3>}
                      </Spring>
                    </>
                  )}
                </VisibilitySensor>
                <div className='area-filter'>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlnsBx="http://www.w3.org/2000/svg">
                    <path d="M22 3H2L10 12.46V19L14 21V12.46L22 3Z" stroke="#606065" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                  <ul className='filter-itens'>
                    <li className={`${this.state.class === 'all' ? "active" : ""}`}><button className='all' onClick={(e) => this.clickMenu('all', 'all')}><p>All Projects</p></button></li>
                    <li className={`${this.state.class === 'custom-homes' ? "active" : ""}`}><button className='custom' onClick={(e) => this.clickMenu('custom-homes')}><p>Custom Homes</p></button></li>
                    <li className={`${this.state.class === 'interior-design' ? "active" : ""}`}><button className='interior' onClick={(e) => this.clickMenu('interior-design')}><p>Interior Design</p></button></li>
                  </ul>
                </div>
              </div>
            </div>
          </section>


          {/* <section id="slider-interior" className={`area-filtro ${this.state.class === 'interior-design' ? "active" : ""}`}>
            <div className="container full-image">
              <div className="area-slider column masonry">
                
                <div className="masonry-brick">
                  <div className="">
                    <VisibilitySensor partialVisibility={true} once>
                      {({ isVisible }) => (
                        <>
                        <Spring to={{ opacity: isVisible ? '1' : "0", transform: isVisible ? 'translateY(0px)' : "translateY(200px)" }} >
                          {props => <div className={` ${isVisible ? "active" : ""}`} style={{ ...props }}><img alt="" src={interiorPhoto1} /></div>}
                        </Spring>
                      </>
                      )}
                    </VisibilitySensor>
                  </div>
                </div>
                <div className="masonry-brick">
                  <div className="">
                    <VisibilitySensor partialVisibility={true} once>
                      {({ isVisible }) => (
                        <>
                        <Spring to={{ opacity: isVisible ? '1' : "0", transform: isVisible ? 'translateY(0px)' : "translateY(200px)" }} >
                          {props => <div className={` ${isVisible ? "active" : ""}`} style={{ ...props }}><img alt="" src={interiorPhoto2} /></div>}
                        </Spring>
                      </>
                      )}
                    </VisibilitySensor>
                  </div>
                </div>
                <div className="masonry-brick">
                  <div className="">
                    <VisibilitySensor partialVisibility={true} once>
                      {({ isVisible }) => (
                        <>
                        <Spring to={{ opacity: isVisible ? '1' : "0", transform: isVisible ? 'translateY(0px)' : "translateY(200px)" }} >
                          {props => <div className={` ${isVisible ? "active" : ""}`} style={{ ...props }}><img alt="" src={interiorPhoto3} /></div>}
                        </Spring>
                      </>
                      )}
                    </VisibilitySensor>
                  </div>
                </div>
                <div className="masonry-brick">
                  <div className="">
                    <VisibilitySensor partialVisibility={true} once>
                      {({ isVisible }) => (
                        <>
                        <Spring to={{ opacity: isVisible ? '1' : "0", transform: isVisible ? 'translateY(0px)' : "translateY(200px)" }} >
                          {props => <div className={` ${isVisible ? "active" : ""}`} style={{ ...props }}><img alt="" src={interiorPhoto4} /></div>}
                        </Spring>
                      </>
                      )}
                    </VisibilitySensor>
                  </div>
                </div>
                <div className="masonry-brick">
                  <div className="">
                    <VisibilitySensor partialVisibility={true} once>
                      {({ isVisible }) => (
                        <>
                        <Spring to={{ opacity: isVisible ? '1' : "0", transform: isVisible ? 'translateY(0px)' : "translateY(200px)" }} >
                          {props => <div className={` ${isVisible ? "active" : ""}`} style={{ ...props }}><img alt="" src={interiorPhoto5} /></div>}
                        </Spring>
                      </>
                      )}
                    </VisibilitySensor>
                  </div>
                </div>
                <div className="masonry-brick">
                  <div className="">
                    <VisibilitySensor partialVisibility={true} once>
                      {({ isVisible }) => (
                        <>
                        <Spring to={{ opacity: isVisible ? '1' : "0", transform: isVisible ? 'translateY(0px)' : "translateY(200px)" }} >
                          {props => <div className={` ${isVisible ? "active" : ""}`} style={{ ...props }}><img alt="" src={interiorPhoto6} /></div>}
                        </Spring>
                      </>
                      )}
                    </VisibilitySensor>
                  </div>
                </div>
                <div className="masonry-brick">
                  <div className="">
                    <VisibilitySensor partialVisibility={true} once>
                      {({ isVisible }) => (
                        <>
                        <Spring to={{ opacity: isVisible ? '1' : "0", transform: isVisible ? 'translateY(0px)' : "translateY(200px)" }} >
                          {props => <div className={` ${isVisible ? "active" : ""}`} style={{ ...props }}><img alt="" src={interiorPhoto7} /></div>}
                        </Spring>
                      </>
                      )}
                    </VisibilitySensor>
                  </div>
                </div>
                <div className="masonry-brick">
                  <div className="">
                    <VisibilitySensor partialVisibility={true} once>
                      {({ isVisible }) => (
                        <>
                        <Spring to={{ opacity: isVisible ? '1' : "0", transform: isVisible ? 'translateY(0px)' : "translateY(200px)" }} >
                          {props => <div className={` ${isVisible ? "active" : ""}`} style={{ ...props }}><img alt="" src={interiorPhoto8} /></div>}
                        </Spring>
                      </>
                      )}
                    </VisibilitySensor>
                  </div>
                </div>
                <div className="masonry-brick">
                  <div className="">
                    <VisibilitySensor partialVisibility={true} once>
                      {({ isVisible }) => (
                        <>
                        <Spring to={{ opacity: isVisible ? '1' : "0", transform: isVisible ? 'translateY(0px)' : "translateY(200px)" }} >
                          {props => <div className={` ${isVisible ? "active" : ""}`} style={{ ...props }}><img alt="" src={interiorPhoto9} /></div>}
                        </Spring>
                      </>
                      )}
                    </VisibilitySensor>
                  </div>
                </div>
                <div className="masonry-brick">
                  <div className="">
                    <VisibilitySensor partialVisibility={true} once>
                      {({ isVisible }) => (
                        <>
                        <Spring to={{ opacity: isVisible ? '1' : "0", transform: isVisible ? 'translateY(0px)' : "translateY(200px)" }} >
                          {props => <div className={` ${isVisible ? "active" : ""}`} style={{ ...props }}><img alt="" src={interiorPhoto10} /></div>}
                        </Spring>
                      </>
                      )}
                    </VisibilitySensor>
                  </div>
                </div>
                
              </div>
            </div>
          </section> */}

          <section id="slider">
            <div className="container full-image">
              <div className="area-slider column">

                <div id="case1" className={`item-slider area-filtro ${this.state.class === 'custom-homes' || this.state.class === 'all' ? "active" : ""}`}>
                  {/* <VisibilitySensor once>
                    {({ isVisible }) => (
                      <>
                      <Spring to={{ opacity: isVisible ? '1' : "0" }} >
                        {props => <div className="step-portfolio"></div>}
                      </Spring>
                    </>
                    )}
                  </VisibilitySensor> */}
                  <div className="item-area">
                    <VisibilitySensor partialVisibility={true} once>
                      {({ isVisible }) => (
                        <>
                          <Spring to={{ opacity: isVisible ? '1' : "0", transform: isVisible ? 'translateY(0px)' : "translateY(200px)" }} >
                            {props => <div className={`image-effect ${isVisible ? "active" : ""}`} style={{ ...props }}><Link to="/portfolio/949-mississauga">
                              <picture>
                                <source media="(max-width: 1170px)" srcSet={caseStudy1M} type="image/jpg" />
                                <source media="(min-width: 1171px)" srcSet={caseStudy1} type="image/jpg" />
                                <img src={caseStudy1} alt="949 Mississauga" />
                              </picture>
                              <p><span>949 Mississauga</span></p></Link></div>}
                          </Spring>
                        </>
                      )}
                    </VisibilitySensor>
                  </div>
                </div>

                <div id="case2" className={`item-slider area-filtro ${this.state.class === 'custom-homes' || this.state.class === 'all' ? "active" : ""}`}>
                  {/* <VisibilitySensor once>
                    {({ isVisible }) => (
                      <>
                      <Spring to={{ opacity: isVisible ? '1' : "0" }} >
                        {props => <div className="step-portfolio"></div>}
                      </Spring>
                    </>
                    )}
                  </VisibilitySensor> */}
                  <div className="item-area">
                    <VisibilitySensor partialVisibility={true} once>
                      {({ isVisible }) => (
                        <>
                          <Spring to={{ opacity: isVisible ? '1' : "0", transform: isVisible ? 'translateY(0px)' : "translateY(200px)" }} >
                            {props => <div className={`image-effect ${isVisible ? "active" : ""}`} style={{ ...props }}><Link to="/portfolio/7176-puslinch">
                              <picture>
                                <source media="(max-width: 1170px)" srcSet={caseStudy2M} type="image/jpg" />
                                <source media="(min-width: 1171px)" srcSet={caseStudy2} type="image/jpg" />
                                <img src={caseStudy2} alt="7176 Puslinch" />
                              </picture>
                              <p><span>7176 Puslinch </span></p></Link></div>}
                          </Spring>
                        </>
                      )}
                    </VisibilitySensor>
                  </div>
                </div>

                <div id="case3" className={`item-slider area-filtro ${this.state.class === 'custom-homes' || this.state.class === 'all' ? "active" : ""}`}>
                  {/* <VisibilitySensor once>
                    {({ isVisible }) => (
                      <>
                      <Spring to={{ opacity: isVisible ? '1' : "0" }} >
                        {props => <div className="step-portfolio"></div>}
                      </Spring>
                    </>
                    )}
                  </VisibilitySensor> */}
                  <div className="item-area">
                    <VisibilitySensor partialVisibility={true} once>
                      {({ isVisible }) => (
                        <>
                          <Spring to={{ opacity: isVisible ? '1' : "0", transform: isVisible ? 'translateY(0px)' : "translateY(200px)" }} >
                            {props => <div className={`image-effect ${isVisible ? "active" : ""}`} style={{ ...props }}><Link to="/portfolio/135-stoneycreek">
                              <picture>
                                <source media="(max-width: 1170px)" srcSet={caseStudy3M} type="image/jpg" />
                                <source media="(min-width: 1171px)" srcSet={caseStudy3} type="image/jpg" />
                                <img src={caseStudy3} alt="135 Stoney Creek" />
                              </picture>
                              <p><span>135 Stoney Creek</span></p></Link></div>}
                          </Spring>
                        </>
                      )}
                    </VisibilitySensor>
                  </div>
                </div>

                <div id="case4" className={`item-slider area-filtro ${this.state.class === 'custom-homes' || this.state.class === 'all' ? "active" : ""}`}>
                  {/* <VisibilitySensor once>
                    {({ isVisible }) => (
                      <>
                      <Spring to={{ opacity: isVisible ? '1' : "0" }} >
                        {props => <div className="step-portfolio"></div>}
                      </Spring>
                    </>
                    )}
                  </VisibilitySensor> */}
                  <div className="item-area">
                    <VisibilitySensor partialVisibility={true} once>
                      {({ isVisible }) => (
                        <>
                          <Spring to={{ opacity: isVisible ? '1' : "0", transform: isVisible ? 'translateY(0px)' : "translateY(200px)" }} >
                            {props => <div className={`image-effect ${isVisible ? "active" : ""}`} style={{ ...props }}><Link to="/portfolio/109-oakville">
                              <picture>
                                <source media="(max-width: 1170px)" srcSet={caseStudy4M} type="image/jpg" />
                                <source media="(min-width: 1171px)" srcSet={caseStudy4} type="image/jpg" />
                                <img src={caseStudy4} alt="109 Oakville" />
                              </picture>
                              <p><span>109 Oakville</span></p></Link></div>}
                          </Spring>
                        </>
                      )}
                    </VisibilitySensor>
                  </div>
                </div>

                <div id="case5" className={`item-slider area-filtro ${this.state.class === 'custom-homes' || this.state.class === 'all' ? "active" : ""}`}>
                  {/* <VisibilitySensor once>
                    {({ isVisible }) => (
                      <>
                      <Spring to={{ opacity: isVisible ? '1' : "0" }} >
                        {props => <div className="step-portfolio"></div>}
                      </Spring>
                    </>
                    )}
                  </VisibilitySensor> */}
                  <div className="item-area">
                    <VisibilitySensor partialVisibility={true} once>
                      {({ isVisible }) => (
                        <>
                          <Spring to={{ opacity: isVisible ? '1' : "0", transform: isVisible ? 'translateY(0px)' : "translateY(200px)" }} >
                            {props => <div className={`image-effect ${isVisible ? "active" : ""}`} style={{ ...props }}><Link to="/portfolio/26-markham">
                              <picture>
                                <source media="(max-width: 1170px)" srcSet={caseStudy9M} type="image/jpg" />
                                <source media="(min-width: 1171px)" srcSet={caseStudy9} type="image/jpg" />
                                <img src={caseStudy9} alt="26 Markham" />
                              </picture>
                              <p><span>26 Markham</span></p></Link></div>}
                          </Spring>
                        </>
                      )}
                    </VisibilitySensor>
                  </div>
                </div>

                <div id="case6" className={`item-slider area-filtro ${this.state.class === 'custom-homes' || this.state.class === 'all' ? "active" : ""}`}>
                  {/* <VisibilitySensor once>
                    {({ isVisible }) => (
                      <>
                      <Spring to={{ opacity: isVisible ? '1' : "0" }} >
                        {props => <div className="step-portfolio"></div>}
                      </Spring>
                    </>
                    )}
                  </VisibilitySensor> */}
                  <div className="item-area">
                    <VisibilitySensor partialVisibility={true} once>
                      {({ isVisible }) => (
                        <>
                          <Spring to={{ opacity: isVisible ? '1' : "0", transform: isVisible ? 'translateY(0px)' : "translateY(200px)" }} >
                            {props => <div className={`image-effect ${isVisible ? "active" : ""}`} style={{ ...props }}><Link to="/portfolio/617-mississauga">
                              <picture>
                                <source media="(max-width: 1170px)" srcSet={caseStudy8M} type="image/jpg" />
                                <source media="(min-width: 1171px)" srcSet={caseStudy8} type="image/jpg" />
                                <img src={caseStudy8} alt="617 Mississauga" />
                              </picture>
                              <p><span>617 Mississauga</span></p></Link></div>}
                          </Spring>
                        </>
                      )}
                    </VisibilitySensor>
                  </div>
                </div>

                <div id="case7" className={`item-slider area-filtro ${this.state.class === 'custom-homes' || this.state.class === 'all' ? "active" : ""}`}>
                  {/* <VisibilitySensor once>
                    {({ isVisible }) => (
                      <>
                      <Spring to={{ opacity: isVisible ? '1' : "0" }} >
                        {props => <div className="step-portfolio"></div>}
                      </Spring>
                    </>
                    )}
                  </VisibilitySensor> */}
                  <div className="item-area">
                    <VisibilitySensor partialVisibility={true} once>
                      {({ isVisible }) => (
                        <>
                          <Spring to={{ opacity: isVisible ? '1' : "0", transform: isVisible ? 'translateY(0px)' : "translateY(200px)" }} >
                            {props => <div className={`image-effect ${isVisible ? "active" : ""}`} style={{ ...props }}><Link to="/portfolio/10193-bolton">
                              <picture>
                                <source media="(max-width: 1170px)" srcSet={caseStudy10M} type="image/jpg" />
                                <source media="(min-width: 1171px)" srcSet={caseStudy10} type="image/jpg" />
                                <img src={caseStudy10} alt="10193 Bolton" />
                              </picture>
                              <p><span>10193 Bolton </span></p></Link></div>}
                          </Spring>
                        </>
                      )}
                    </VisibilitySensor>
                  </div>
                </div>


                <div id="case8" className={`item-slider area-filtro ${this.state.class === 'custom-homes' || this.state.class === 'all' ? "active" : ""}`}>
                  {/* <VisibilitySensor once>
                    {({ isVisible }) => (
                      <>
                      <Spring to={{ opacity: isVisible ? '1' : "0" }} >
                        {props => <div className="step-portfolio"></div>}
                      </Spring>
                    </>
                    )}
                  </VisibilitySensor> */}
                  <div className="item-area">
                    <VisibilitySensor partialVisibility={true} once>
                      {({ isVisible }) => (
                        <>
                          <Spring to={{ opacity: isVisible ? '1' : "0", transform: isVisible ? 'translateY(0px)' : "translateY(200px)" }} >
                            {props => <div className={`image-effect ${isVisible ? "active" : ""}`} style={{ ...props }}><Link to="/portfolio/2-stoneycreek">
                              <picture>
                                <source media="(max-width: 1170px)" srcSet={caseStudy7M} type="image/jpg" />
                                <source media="(min-width: 1171px)" srcSet={caseStudy7} type="image/jpg" />
                                <img src={caseStudy7} alt="2 Stoney Creek" />
                              </picture>
                              <p><span>2 Stoney Creek</span></p></Link></div>}
                          </Spring>
                        </>
                      )}
                    </VisibilitySensor>
                  </div>
                </div>

                <div id="case9" className={`item-slider area-filtro ${this.state.class === 'custom-homes' || this.state.class === 'all' ? "active" : ""}`}>
                  {/* <VisibilitySensor once>
                    {({ isVisible }) => (
                      <>
                      <Spring to={{ opacity: isVisible ? '1' : "0" }} >
                        {props => <div className="step-portfolio"></div>}
                      </Spring>
                    </>
                    )}
                  </VisibilitySensor> */}
                  <div className="item-area">
                    <VisibilitySensor partialVisibility={true} once>
                      {({ isVisible }) => (
                        <>
                          <Spring to={{ opacity: isVisible ? '1' : "0", transform: isVisible ? 'translateY(0px)' : "translateY(200px)" }} >
                            {props => <div className={`image-effect ${isVisible ? "active" : ""}`} style={{ ...props }}><Link to="/portfolio/233-princeedwardcounty">
                              <picture>
                                <source media="(max-width: 1170px)" srcSet={caseStudy11M} type="image/jpg" />
                                <source media="(min-width: 1171px)" srcSet={caseStudy11} type="image/jpg" />
                                <img src={caseStudy11} alt="233 Prince Edward County" />
                              </picture>
                              <p><span>233 Prince Edward County</span></p></Link></div>}
                          </Spring>
                        </>
                      )}
                    </VisibilitySensor>
                  </div>
                </div>

                <div id="case10" className={`item-slider area-filtro ${this.state.class === 'custom-homes' || this.state.class === 'all' ? "active" : ""}`}>
                  {/* <VisibilitySensor once>
                    {({ isVisible }) => (
                      <>
                      <Spring to={{ opacity: isVisible ? '1' : "0" }} >
                        {props => <div className="step-portfolio"></div>}
                      </Spring>
                    </>
                    )}
                  </VisibilitySensor> */}
                  <div className="item-area">
                    <VisibilitySensor partialVisibility={true} once>
                      {({ isVisible }) => (
                        <>
                          <Spring to={{ opacity: isVisible ? '1' : "0", transform: isVisible ? 'translateY(0px)' : "translateY(200px)" }} >
                            {props => <div className={`image-effect ${isVisible ? "active" : ""}`} style={{ ...props }}><Link to="/portfolio/533-vaughan">
                              <picture>
                                <source media="(max-width: 1170px)" srcSet={caseStudy12M} type="image/jpg" />
                                <source media="(min-width: 1171px)" srcSet={caseStudy12} type="image/jpg" />
                                <img src={caseStudy12} alt="533 Vaughan" />
                              </picture>
                              <p><span>533 Vaughan</span></p></Link></div>}
                          </Spring>
                        </>
                      )}
                    </VisibilitySensor>
                  </div>
                </div>

                <div className={`item-slider area-filtro ${this.state.class === 'interior-design' || this.state.class === 'all' ? "active" : ""}`}>
                  {/* <VisibilitySensor once>
                    {({ isVisible }) => (
                      <>
                      <Spring to={{ opacity: isVisible ? '1' : "0" }} >
                        {props => <div className="step-portfolio"></div>}
                      </Spring>
                    </>
                    )}
                  </VisibilitySensor> */}
                  <div className="item-area">
                    <VisibilitySensor partialVisibility={true} once>
                      {({ isVisible }) => (
                        <>
                          <Spring to={{ opacity: isVisible ? '1' : "0", transform: isVisible ? 'translateY(0px)' : "translateY(200px)" }} >
                            {props => <div className={`image-effect ${isVisible ? "active" : ""}`} style={{ ...props }}><Link to="/portfolio/interior-design">
                              <picture>
                                <source media="(max-width: 1170px)" srcSet={interiorPhoto1M} type="image/jpg" />
                                <source media="(min-width: 1171px)" srcSet={interiorPhoto1} type="image/jpg" />
                                <img src={interiorPhoto1} alt="Interior Design" />
                              </picture>
                              <p><span>Interior Design</span></p></Link></div>}
                          </Spring>
                        </>
                      )}
                    </VisibilitySensor>
                  </div>
                </div>

                <div className={`item-slider area-filtro ${this.state.class === 'interior-design' || this.state.class === 'all' ? "active" : ""}`}>
                  {/* <VisibilitySensor once>
                    {({ isVisible }) => (
                      <>
                      <Spring to={{ opacity: isVisible ? '1' : "0" }} >
                        {props => <div className="step-portfolio"></div>}
                      </Spring>
                    </>
                    )}
                  </VisibilitySensor> */}
                  <div className="item-area">
                    <VisibilitySensor partialVisibility={true} once>
                      {({ isVisible }) => (
                        <>
                          <Spring to={{ opacity: isVisible ? '1' : "0", transform: isVisible ? 'translateY(0px)' : "translateY(200px)" }} >
                            {props => <div className={`image-effect ${isVisible ? "active" : ""}`} style={{ ...props }}><Link to="/portfolio/projects-turkey">
                              <picture>
                                <source media="(max-width: 1170px)" srcSet={projectTurkeyM} type="image/jpg" />
                                <source media="(min-width: 1171px)" srcSet={projectTurkey} type="image/jpg" />
                                <img src={projectTurkey} alt="Projects Turkey" />
                              </picture>
                              <p><span>Projects Turkey</span></p></Link></div>}
                          </Spring>
                        </>
                      )}
                    </VisibilitySensor>
                  </div>
                </div>
                {/* 
                <div className="item-slider" id="case7">
                  //   <VisibilitySensor once>
                  //   {({ isVisible }) => (
                  //     <>
                  //     <Spring to={{ opacity: isVisible ? '1' : "0" }} >
                  //       {props => <div className="step-portfolio"></div>}
                  //     </Spring>
                  //   </>
                  //   )}
                  // </VisibilitySensor>
                  <div className="item-area">
                    <VisibilitySensor partialVisibility={true} once>
                      {({ isVisible }) => (
                        <>
                        <Spring to={{ opacity: isVisible ? '1' : "0", transform: isVisible ? 'translateY(0px)' : "translateY(200px)" }} >
                          {props => <div className={`image-effect ${isVisible ? "active" : ""}`} style={{ ...props }}><Link to="/portfolio/casa-portugal"><img alt="" src={caseStudy5} />
                          <p>Transitional Style <br /> <span>Binbrook ON</span></p></Link></div>}
                        </Spring>
                      </>
                      )}
                    </VisibilitySensor>
                  </div>
                </div>


                <div className="item-slider" id="case8">
                  //   <VisibilitySensor once>
                  //   {({ isVisible }) => (
                  //     <>
                  //     <Spring to={{ opacity: isVisible ? '1' : "0" }} >
                  //       {props => <div className="step-portfolio"></div>}
                  //     </Spring>
                  //   </>
                  //   )}
                  // </VisibilitySensor>
                  <div className="item-area">
                    <VisibilitySensor partialVisibility={true} once>
                      {({ isVisible }) => (
                        <>
                        <Spring to={{ opacity: isVisible ? '1' : "0", transform: isVisible ? 'translateY(0px)' : "translateY(200px)" }} >
                          {props => <div className={`image-effect ${isVisible ? "active" : ""}`} style={{ ...props }}><Link to="/portfolio/195-galaxy-blvd"><img alt="" src={caseStudy6} />
                          <p>Transitional Style <br /> <span>Binbrook ON</span></p></Link></div>}
                        </Spring>
                      </>
                      )}
                    </VisibilitySensor>
                  </div>
                </div> */}

              </div>
            </div>
          </section>

          {/* <section id="testimonial">
            <div className="container">
              <div className="area-testimonial column">
                <CarouselTestimonial testimonials={testimonials} />
              </div>
            </div>
          </section> */}
        </div>
        <Footer />
      </>
    );
  }
}

export default Home;